import { ethers } from "ethers";
import { myToast, useAccount, useBalance, useData } from "fuhi-web3ts";
import React, {
 createContext,
 useContext,
 useEffect,
 useMemo,
 useState,
} from "react";
import { ENV } from "../environment";
import { useNftContract } from "../pages/NFT/hooks/useNftContract";
const context = createContext({});

export const NFTProvider = (p) => {
 const [dataFromContract, setData] = useState([]);
 const { account } = useAccount();
 const { balance } = useBalance();
 const { data: fullData } = useData({
  path: "/data/nfts/list.json",
  default: [],
 });
 const { marketContract, nftContract } = useNftContract();
 useEffect(() => {
  if (marketContract && fullData.length > 0 && dataFromContract.length === 0) {
   const contractOwner = "0x76ebdad3f69695dbd60cb64411562325ccfbeced";
   marketContract.getNfts().then(async (resp) => {
    const _data = [];
    const promises = [];
    for (let nftData of resp) {
     debugger;
     if (resp.owner === contractOwner) continue;
     const data = {};
     promises.push(
      fetch(nftData.tokenURI)
       .then((t) => t.json())
       .catch(() => ({}))
       .then((tokenMeta) => {
        Object.assign(data, tokenMeta, {
         price: Number(nftData.price) / 1e18,
         marketItemId: nftData.marketItemId,
         createTime: nftData.createTime,
         owner: nftData.owner,
        });
       })
     );
     _data.push(data);
    }
    await Promise.all(promises);
    setData(_data);
   });
  }
 }, [marketContract, fullData]);
 const data = useMemo(() => {
  return dataFromContract.length === 0 ? fullData : dataFromContract;
 }, [fullData, dataFromContract]);

 const buy = async ({ marketItemId, price }) => {
  if (Number(balance) < Number(price)) {
   return myToast.toastWait(
    { message: "Not enough balance" },
    { type: "error" }
   );
  }

  let _toast;
  let isApprovedForAll = await nftContract.isApprovedForAll(
   account,
   ENV.marketContractAddress
  );
  if (!isApprovedForAll) {
   _toast = myToast.toastWait({
    message: "Require Approval For Market Contract",
   });
   try {
    const process = await nftContract.setApprovalForAll(
     ENV.marketContractAddress,
     true
    );
    _toast?.();
    _toast = myToast.waitTransaction();
    await process.wait();
    _toast?.();
   } catch (error) {
    myToast.toastWait(error.reason, { type: "error" });
   }
   let isApprovedForAll = await nftContract.isApprovedForAll(
    account,
    ENV.marketContractAddress
   );
   if (!isApprovedForAll) {
    return myToast.toastWait({ message: "Please, try again!" });
   }
  }
  try {
   const etherValue = ethers.utils.parseEther(price + "").toBigInt();
   const process = await marketContract.buyNFT(marketItemId, {
    value: etherValue,
   });
   _toast = myToast.waitTransaction();
   await process.wait();
   _toast?.();
   myToast.toast("Congratulations! You have successfully purchased the NFT.", {
    type: "success",
   });
   const _nftData = data.find((f) => f.marketItemId === marketItemId);
   if (_nftData) {
    _nftData.owner = account;
    setData([...data]);
   }
  } catch (error) {
   myToast.toast(error.message, { type: "error" });
  }
 };

 const setNFTPrice = async (marketItemId, newPrice) => {
  try {
   let _toast;
   if (newPrice >= 0) {
    const etherValue = ethers.utils.parseEther(newPrice + "").toBigInt();
    const process = await marketContract.setPrice(marketItemId, etherValue);
    _toast = myToast.waitTransaction();
    await process.wait();
    _toast?.();
    if (newPrice > 0) {
     myToast.toast("Selling price updated successfully!", { type: "success" });
    } else {
     myToast.toast("Selling cancelled successfully!", { type: "success" });
    }
   }
  } catch (error) {
   myToast.toast(error.reason || error.message, { type: "error" });
  }
 };

 return (
  <context.Provider
   value={{
    data,
    buy,
   }}
  >
   {p.children}
  </context.Provider>
 );
};

export const useNFT = () => useContext(context);
