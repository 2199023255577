import React from "react";
import { ENV } from "../../../environment";
import { Link } from "../../Link";

const CurrentcySection = () => {
 return (
  <div className="section bg-gray-500 zuzu-section-padding-bottom overflow-hidden extra-padding">
   <div className="container">
    <div className="row">
     <div className="col-lg-6 order-lg-2">
      <div className="zuzu-circle-thumb wow fadeInUpX" data-wow-delay="0.1s">
       <img src={"/images/user.png"} alt="" />
      </div>
     </div>
     <div className="col-lg-6 d-flex align-items-center">
      <div className="zuzu-default-content dark large font-cabin m_right">
       <h2>The NFT platform enables multi-chain interaction</h2>
       <p>
        At NFTG, we enable users to participate in the exchange of NFT assets
        across various networks, initially with BNB Chain, and network expansion
        will be rapidly integrated in the next stages.
       </p>
       <p>
        In addition, we also support payments to a number of separate payment
        cards on our platform.
       </p>
       <div className="zuzu-btn-wrap">
        <Link className="zuzu-btn pill btn-blue" to={ENV.whitepaper}>
         View more
        </Link>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default CurrentcySection;
