import React from "react";
import UserItem from "./UserItem";

import { ENV } from "../../environment";
import Detail from "./Detail";
import { useNFTDetail } from "./NFTDetailContext";

const BlogLeft = () => {
 const { value } = useNFTDetail();
 if (!value) return <></>;
 return (
  <div className="blog-col blog-col-l">
   <div
    className="bl-l-hd"
    style={{ backgroundImage: `url("${value.image}")` }}
   ></div>
   <div className="bl-l-hd-desc">
    <div className="hd-desc">
     <div className="hd-desc-item">
      <div className="hd-desc-title">Created by</div>
      <UserItem
       img={"/images/nft-contract-avatar.jpg"}
       title={ENV.tokenSymbol}
       id={"@NFTG_nft"}
       isTitleWhite={true}
      />
     </div>
     <div className="hd-desc-item">
      <div className="hd-desc-title">Collection</div>
      <div className="hd-desc-content">
       <div className="hd-desc-img">
        <img src={"/images/robot.jpg"} alt=""></img>
       </div>
       <div className="box-user-info">
        <div className="tt-white box-user-title">{ENV.tokenSymbol}</div>
        <div className="box-user-id isFrame">
         <span>{ENV.tokenSymbol}</span>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <Detail />
  </div>
 );
};

export default BlogLeft;
