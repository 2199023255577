export const TeamItem = ({ item }) => {
 return (
  <div className={`col-xl-4 col-md-6 `} data-aos="fade-up" data-aos-delay="300">
   <div className="zuzu-team-wrap dark wow fadeInUpX" data-wow-delay="0s">
    <div className="zuzu-team-thumb">
     <img src={item.avatar} alt="" />
    </div>
    <div className="zuzu-team-data">
     <h4>{item.name}</h4>
     <p>{item.position}</p>
    </div>
   </div>
  </div>
 );
};
