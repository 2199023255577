import React from "react";

import { ENV } from "../../environment.js";
import { Link } from "../Link";
const HeroSection = () => {
 return (
  <div className="zuzu-hero-section3">
   <div className="container">
    <div className="zuzu-hero-content zuzu-dark-content zuzu-hero-content3 font-syne">
     <h1 className="wow fadeInUpX" data-wow-delay="0s">
      Blockchain platform for next generation creators{" "}
     </h1>
     <p className="wow fadeInUpX" data-wow-delay="0.25s">
      We are a private decentralized blockchain platform supporting innovative
      financial tools such as decentralized applications & NFTs. It can be
      considered a modern variation of 5G technology, trading cards attached to
      digital assets.
     </p>
     <div
      className="zuzu-hero-btn-wrap justify-content-center wow fadeInUpX"
      data-wow-delay="0.40s"
     >
      <Link className="zuzu-btn active gradient text-white" to="/presale">
       Buy {ENV.tokenSymbol}
      </Link>
      <Link className="zuzu-btn gradient text-white" to={ENV.whitepaper}>
       Whitepaper
      </Link>
     </div>
    </div>
   </div>
   <div className="zuzu-hero-box box1">
    <img src={"/images/box.png"} alt="" />
   </div>
   <div className="zuzu-hero-box box2">
    <img src={"/images/box.png"} alt="" />
   </div>
  </div>
 );
};

export default HeroSection;
