import React from "react";

const VideoSection = () => {
 return (
  <>
   <div className="bg-gray-500">
    <div className="container">
     <div className="zuzu-section-title">
      <div className="zuzu-default-content sm dark font-syne">
       <h2>
        We open new opportunities at Blockchain with the combination of 5G and
        NFTs
       </h2>
      </div>
     </div>
     <div className="zuzu-video-thumb wow fadeInUpX m-0" data-wow-delay=".10s">
      <img src={"/images/video-thum-features.png"} alt="" />
     </div>
    </div>
   </div>
   <div className="section bg-gray-500 zuzu-section-padding">
    <div className="container">
     <div className="row">
      <div className="col-lg-5">
       <div className="zuzu-earth-thumb wow fadeInUpX" data-wow-delay=".10s">
        <img src={"/images/hand.png"} alt="" />
        <div className="zuzu-animation-circle">
         <img src={"/imags/circle.png"} alt="" />
        </div>
       </div>
      </div>
      <div className="col-lg-6 offset-lg-1 d-flex align-items-center">
       <div className="zuzu-default-content sm dark font-syne">
        <h2>Maximum security for your digital assets forever</h2>
        <p>
         It is secure and ideal for storing digital assets, it is very
         decentralized in nature. By using a cryptographic hash function, each
         asset can be securely stored on the blockchain and tracked as it
         changes hands.
        </p>
        <p>
         Blockchains are technology solutions that support digital assets.
         Blockchain is essentially a method of securely recording data on a
         peer-to-peer network.
        </p>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default VideoSection;
