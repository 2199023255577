import { useAddToken, useContractAddress } from "fuhi-web3ts";
import { ENV } from "../../../environment";

export const AddToken = () => {
 const { shortAddress } = useContractAddress();
 const { isSupport, addToken } = useAddToken();
 return (
  <div
   className="flex flex-col flex-1 items-center cursor-pointer "
   onClick={addToken}
  >
   <div className="flex items-center text-primary-100 text-base leading-[16px] md:text-xl md:leading-[20px] lg:text-2xl lg:leading-[24px]">
    <div className="mr-[8px]"> {shortAddress}</div>
   </div>
   <div className="text-primary-54 mt-[8px] text-xs leading-[12px] md:text-sm md:leading-[14px]">
    {isSupport ? `Add ${ENV.tokenSymbol} Token` : "Contract address"}
   </div>
  </div>
 );
};
