import { ENV } from "../../../environment";
import { ConnectWalletButton } from "./ConnectWalletButton";

export const WalletDisconnect = () => {
 return (
  <div className="flex flex-col justify-center items-center h-[324px] md:h-[264px] lg:h-full">
   <div className="ml-[8px] md:ml-[16px]">
    <ConnectWalletButton />
   </div>
   <div className="text-sm leading-[24px] text-primary-54 mt-[16px]">
    Connect Wallet to see your {ENV.tokenSymbol}.
   </div>
  </div>
 );
};
