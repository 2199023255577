import React from 'react'

const UserItem = (props) => {
    return (
        <div className='blog-box-user'>
            <div className="box-user-avt history">
                <img src={props.img} alt=''></img>
            </div>
            <div className="box-user-info">
                <div className={props.isTitleWhite ? "tt-white box-user-title" : "box-user-title"}>{props.title}</div>
                <div className={props.isFrame ? "box-user-id isFrame" : "box-user-id"}><span>{props.id}</span></div>
            </div>
        </div>
    )
}

export default UserItem