import React, { useEffect, useState } from "react";

const GoTopButton = (props) => {
 const [isShow, setShow] = useState(false);

 useEffect(() => {
  setShow(props.show);
 }, [props.show]);

 const show = {
  display: isShow ? "block" : "none",
 };

 const handleClick = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };
 return (
  <div className="zuzu-go-top" style={show} onClick={() => handleClick()}>
   <img src={"/images/arrow-right-white.svg"} alt="" />
  </div>
 );
};

export default GoTopButton;
