import React from "react";

const SubcribeSection = () => {
 return (
  <div className="section bg-gray-500">
   <div className="container">
    <div className="zuzu-newsletter-wrap">
     <div className="zuzu-default-content sm dark font-syne">
      <h2>Join our community of faith-centered blockchain enthusiasts</h2>
      <div
       className="zuzu-newsletter-field wow fadeInUpX"
       data-wow-delay=".10s"
      >
       <input type="email" placeholder="Type your email here" />
       <button id="zuzu-newsletter-btn" type="submit">
        Subscribe
       </button>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default SubcribeSection;
