import { useConnect } from "fuhi-web3ts";
import { WalletConnected } from "./WalletConnected";
import { WalletDisconnect } from "./WalletDisconnect";
export const SwapContainer = () => {
 const { isConnected } = useConnect();
 return (
  <div className="bg-[rgba(0,0,0,0.3)] backdrop-blur-[25px] rounded-[12px] p-[24px] w-full">
   {isConnected ? <WalletConnected /> : <WalletDisconnect />}
  </div>
 );
};
