import { useData } from "fuhi-web3ts";
import React from "react";
import { Link } from "../Link";
import { TeamItem } from "../Team/TeamItem";

const MeetOurTeam = () => {
 const { data: fullData } = useData({ path: "/data/teams.json", default: [] });
 const data = React.useMemo(
  () => (fullData.length > 3 ? fullData.slice(0, 3) : fullData),
  [fullData]
 );
 return (
  <div className="section zuzu-section-padding3 bg-gray-500">
   <div className="container">
    <div className="zuzu-2column-title">
     <div className="row">
      <div className="col-lg-6">
       <div className="zuzu-default-content dark">
        <h5>
         <strong>Meet our team</strong>
        </h5>
        <h2>Our dedicated team is always ready to deliver new vision</h2>
       </div>
      </div>
      <div className="col-lg-6 d-lg-flex justify-content-end align-items-center">
       <Link className="zuzu-btn pill btn-blue" to="/team">
        Meet all members
       </Link>
      </div>
     </div>
    </div>
    <div className="row">
     {data.map((item, idx) => (
      <TeamItem item={item} key={idx} />
     ))}
    </div>
   </div>
  </div>
 );
};

export default MeetOurTeam;
