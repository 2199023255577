import "./App.css";
import ScrollToTop from "./components/ScrollToTop.jsx";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/fontawesome.css";

// import './assets/css/slick.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "./assets/css/animate.css";
import "./assets/css/icomoon-font.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/swiper-bundle.min.css";

import { useEffect, useState } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "./assets/css/app.css";
import "./assets/css/main.css";
import "./assets/custom.css";

import { Web3TsProvider, bsc } from "fuhi-web3ts";
import { ToastContainer } from "react-toastify";
import AboutUs from "./components/AboutUs/AboutUs";
import BlockChain from "./components/BlockChain/BlockChain";
import Collections from "./components/Collections/Collections";
import ContactDarkMap from "./components/ContactUs/ContactDarkMap";
import CryptoCard from "./components/CryptoCard/CryptoCard";
import GoTopButton from "./components/GoTop/GoTopButton";
import NFTDetail from "./components/NFTDetail";
import NftPlatform from "./components/NFTPlatform/NftPlatform";
import TeamDark from "./components/Team/TeamDark";
import Navbar from "./components/navbar/navbar";
import { ENV } from "./environment";
import { BuyPage } from "./pages/Buy";
import { StakingPage } from "./pages/Staking";

import "@fontsource/cabin";
import "@fontsource/cabin/600.css";
import "@fontsource/cabin/700.css";
import "@fontsource/inter";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/public-sans";
import "@fontsource/public-sans/600.css";
import "@fontsource/public-sans/700.css";
import "@fontsource/public-sans/800.css";
import "@fontsource/space-grotesk";
import "@fontsource/space-grotesk/500.css";
import "@fontsource/space-grotesk/600.css";
import "@fontsource/space-grotesk/700.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Footer from "./components/Layout/FooterSection";
import { NFTProvider } from "./context/NFTContext";

const Layout = () => {
 const [isFixMenu, setFixMenu] = useState(false);
 useEffect(() => {
  window.addEventListener("scroll", (e) => {
   if (window.scrollY > 0) {
    setFixMenu(true);
   } else {
    setFixMenu(false);
   }
  });
 });

 return (
  <>
   <ScrollToTop />
   <Outlet />
   <Footer />
   <GoTopButton show={isFixMenu} />
  </>
 );
};
const Layout2 = () => {
 const [isFixMenu, setFixMenu] = useState(false);
 useEffect(() => {
  window.addEventListener("scroll", (e) => {
   if (window.scrollY > 0) {
    setFixMenu(true);
   } else {
    setFixMenu(false);
   }
  });
 });

 return (
  <>
   <Navbar fix={isFixMenu} />
   <Outlet />
  </>
 );
};

const router = createBrowserRouter([
 {
  path: "/",
  element: <Layout />,
  children: [
   {
    path: "/",
    element: <Layout2 />,
    children: [
     { path: "/", index: true, element: <CryptoCard /> },
     { path: "team", element: <TeamDark /> },
     { path: "contact", element: <ContactDarkMap /> },
     { path: "features", element: <BlockChain /> },
     { path: "nftplatform", element: <NftPlatform /> },
     { path: "aboutus", element: <AboutUs /> },
     { path: "collections", element: <Collections /> },
     { path: "staking", element: <StakingPage /> },
     { path: "nft/:marketItemId", element: <NFTDetail /> },
    ],
   },
   {
    path: "presale",
    element: <BuyPage />,
   },
  ],
 },
]);
function App() {
 useEffect(() => {
  AOS.init({
   duration: 500,
  });
 }, []);
 return (
  <Web3TsProvider
   config={{
    buyContract: {
     contractAddress: ENV.buyContractAddress,
     buyMethod: "Presale",
     buyUSDTMethod: "PresaleWithUSDT",
     referralPath: ENV.referralPath,
     disableBuy: ENV.disableBuy === true,
    },
    mainContract: {
     contractAddress: ENV.contractAddress,
     logo: window.location.origin + "/logo4wallet.png",
     symbol: ENV.tokenSymbol,
    },
    chains: [bsc],
    metadata: {
     url: window.location.origin,
     ...ENV.metadata,
     icons: [window.location.origin + "/logo4wallet.png"],
    },
    projectId: "9270d2d3c9f0edb91054b7b10b0cf442",
    walletIds: [
     "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    ],
   }}
  >
   <NFTProvider>
    <ToastContainer hideProgressBar position="bottom-left" />
    <RouterProvider router={router} />
   </NFTProvider>
  </Web3TsProvider>
 );
}

export default App;
