export const BuyBanner = () => {
 return (
  <div className="max-w-[1440px] mx-auto px-[20px] md:px-[40px] lg:px-[60px] xl:px-[80px] pt-[140px]">
   <div className="text-center">
    <div className="text-xs leading-[15px] font-bold tracking-[0.48em] md:text-base md:leading-[20px] lg:text-xl lg:leading-[25px]">
     THE JOURNEY BEGINS
    </div>
    <div className="text-[28px] leading-[40px] font-bold mt-[8px] md:text-[44px] md:leading-[60px] lg:text-[60px] lg:leading-[80px] lg:mt-0 xl:text-7xl xl:leading-[100px]">
     The Road to The Order
    </div>
    <div className="text-xs leading-[18px] mt-[9px] md:mt-[6px] lg:text-base lg:leading-[24px] lg:mt-[9px] xl:text-lg xl:leading-[27px]">
     Own NFTG Token through trading USDT and BNB
    </div>
   </div>
  </div>
 );
};
