import React from "react";

import { Link } from "../Link";

const CollectionHeader = () => {
 return (
  <div
   className="collect-hd-container"
   style={{
    backgroundImage: "url('/images/collection-grid.png')",
   }}
  >
   <div className="collect-hd">
    <div className="hd-content">
     <img
      src={"/images/all-img/Nerko/ethereum-01.png"}
      alt=""
      style={{ position: "absolute", top: "-20%", left: "50%", width: "40px" }}
     ></img>
     <img
      src={"/images/all-img/Nerko/circle-01.png"}
      alt=""
      style={{ position: "absolute", top: "12%", left: "-10%", width: "16px" }}
     ></img>
     <img
      src={"/images/all-img/Nerko/x.png"}
      alt=""
      style={{ position: "absolute", top: "20%", left: "110%", width: "20px" }}
     ></img>
     <img
      src={"/images/all-img/Nerko/bitcoin-01.png"}
      alt=""
      style={{ position: "absolute", top: "105%", left: "17%", width: "40px" }}
     ></img>
     <img
      src={"/images/all-img/Nerko/circle-03.png"}
      alt=""
      style={{ position: "absolute", top: "113%", left: "76%", width: "20px" }}
     ></img>
     <p className="hd-title">Buy & Sell NFTG Collection</p>
     <p className="hd-desc">Invest and manage all your NFTs in one place.</p>
     <Link to="/presale" className="zuzu-btn btn-blue">
      Buy NFTG
     </Link>
    </div>
   </div>
  </div>
 );
};

export default CollectionHeader;
