import React from "react";
import { ENV } from "../../environment";
import { Link } from "../Link";

const OurMissionSection = () => {
 return (
  <div className="section bg-gray-500 zuzu-section-padding">
   <div className="container">
    <div className="row">
     <div className="col-lg-7 order-lg-2">
      <div className="zuzu-thumb wow fadeInUpX" data-aos="fade-up">
       <img src={"/images/thumb3.png"} alt="" />
      </div>
     </div>
     <div className="col-lg-5 d-flex align-items-center">
      <div className="zuzu-default-content dark">
       <h5>
        {" "}
        <strong>Our mission</strong>{" "}
       </h5>
       <h2 className="font-black">
        Increase economic freedom around the world
       </h2>
       <p>
        Essentially, cryptocurrencies are a technological breakthrough and also
        allow us to build a more free and open financial system, empowering
        people to take control of their work and assets. It is the best tool to
        advance our mission to increase economic freedom around the world
       </p>
       <div className="zuzu-btn-wrap">
        <Link className="zuzu-btn pill btn-blue" to={ENV.whitepaper}>
         View more
        </Link>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default OurMissionSection;
