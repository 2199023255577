import React from "react";
import { useNFTDetail } from "./NFTDetailContext";

const Detail = () => {
 const { value } = useNFTDetail();
 return (
  <div className="dt-container">
   <div className="dt-content">
    <div className="dt-ct-decs">
     <div className="dt-title">Details</div>
     <div className="dt-desc">
      <p>{value.description}</p>
     </div>
    </div>
   </div>
  </div>
 );
};

export default Detail;
