class CMCCService {
 URL = window.location.host.includes("localhost")
  ? "https://nftg.a77k13bucii.site"
  : `${window.location.origin}`;
 getMany({ limit, page }) {
  return fetch(
   // eslint-disable-next-line no-restricted-globals
   ` ${this.URL}/list.php?page=${page}&limit=${limit}`
  )
   .then((t) => t.json())
   .then((res) => res.data || []);
 }
}
export const cmccService = new CMCCService();
