export const IMarketContractABI = [
 {
  inputs: [
   {
    internalType: "uint256",
    name: "marketItemId",
    type: "uint256",
   },
  ],
  name: "getNFT",
  outputs: [
   {
    components: [
     {
      internalType: "uint256",
      name: "marketItemId",
      type: "uint256",
     },
     {
      internalType: "uint256",
      name: "tokenId",
      type: "uint256",
     },
     {
      internalType: "address payable",
      name: "owner",
      type: "address",
     },
     {
      internalType: "uint256",
      name: "price",
      type: "uint256",
     },
     {
      internalType: "string",
      name: "tokenURI",
      type: "string",
     },
     {
      internalType: "uint256",
      name: "createTime",
      type: "uint256",
     },
    ],
    internalType: "struct Marketplace.MarketItem",
    name: "",
    type: "tuple",
   },
  ],
  stateMutability: "view",
  type: "function",
 },
 {
  inputs: [],
  name: "getNfts",
  outputs: [
   {
    components: [
     {
      internalType: "uint256",
      name: "marketItemId",
      type: "uint256",
     },
     {
      internalType: "uint256",
      name: "tokenId",
      type: "uint256",
     },
     {
      internalType: "address payable",
      name: "owner",
      type: "address",
     },
     {
      internalType: "uint256",
      name: "price",
      type: "uint256",
     },
     {
      internalType: "string",
      name: "tokenURI",
      type: "string",
     },
     {
      internalType: "uint256",
      name: "createTime",
      type: "uint256",
     },
    ],
    internalType: "struct Marketplace.MarketItem[]",
    name: "",
    type: "tuple[]",
   },
  ],
  stateMutability: "view",
  type: "function",
 },
 {
  inputs: [
   {
    internalType: "uint256",
    name: "marketItemId",
    type: "uint256",
   },
  ],
  name: "buyNFT",
  outputs: [],
  stateMutability: "payable",
  type: "function",
 },
 {
  inputs: [],
  name: "counter",
  outputs: [
   {
    internalType: "uint256",
    name: "",
    type: "uint256",
   },
  ],
  stateMutability: "view",
  type: "function",
 },
 {
  inputs: [],
  name: "owner",
  outputs: [
   {
    internalType: "address",
    name: "",
    type: "address",
   },
  ],
  stateMutability: "view",
  type: "function",
 },
];

export const INFTABI = [
 {
  inputs: [
   {
    internalType: "address",
    name: "operator",
    type: "address",
   },
   {
    internalType: "bool",
    name: "approved",
    type: "bool",
   },
  ],
  name: "setApprovalForAll",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
 },
 {
  inputs: [
   {
    internalType: "address",
    name: "owner",
    type: "address",
   },
   {
    internalType: "address",
    name: "operator",
    type: "address",
   },
  ],
  name: "isApprovedForAll",
  outputs: [
   {
    internalType: "bool",
    name: "",
    type: "bool",
   },
  ],
  stateMutability: "view",
  type: "function",
 },
];
