import React from "react";
import { ENV } from "../../../environment";
import { Link } from "../../Link";

const Header = () => {
 return (
  <div
   className="zuzu-hero-section bg-cover"
   style={{
    backgroundImage: `url("/images/hero-bg.png")`,
   }}
  >
   <div className="container">
    <div className="row">
     <div className="col-lg-6 d-flex align-items-center">
      <div className="zuzu-hero-content zuzu-dark-content font-cabin">
       <h1 className="wow letter-sp-s2" data-aos="fade-up">
        Diverse NFT system with core technology
       </h1>
       <p className="wow" data-aos="fade-up" data-aos-delay="100">
        NFTG brings a completely new experience with features highly compatible
        with the Blockchain platform. Build a diverse payment system suitable
        for all participants.
       </p>
       <div className="zuzu-hero-btn-wrap">
        <Link
         className="zuzu-btn gradient active text-white wow"
         to="/presale"
         data-aos="fade-up"
         data-aos-delay="200"
        >
         Buy NFTG{" "}
        </Link>
        <Link className="zuzu-popup zuzu-popup-hero" to={ENV.whitepaper}>
         <img src={"/images/all-img/v1/play-button.png"} alt="" />
         <div className="waves wave-1"></div>
         <div className="waves wave-2"></div>
         <div className="waves wave-3"></div>
         Whitepaper
        </Link>
       </div>
      </div>
     </div>
     <div className="col-lg-6">
      <div className="zuzu-hero-card1">
       <img src={"/images/card01.png"} alt="" />
      </div>
      <div className="zuzu-hero-card2">
       <img src={"/images/card02.png"} alt="" />
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default Header;
