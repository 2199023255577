import React from "react";
import { Link } from "../../Link";

const SlideItem = ({ data }) => {
 const { image, name, id, price, marketItemId } = data;
 return (
  <div className="zuzu-card-custom">
   <div className="zuzu-card-image-ct">
    <img src={image} alt="" />
   </div>
   <div className="zuzu-card-data">
    <div className="flex space-x-2 items-center">
     <h3 className="line-clamp-1">{name}</h3>
     <span className="text-gray-700 text-xs">{id}</span>
    </div>
    <div className="zuzu-card-footer-data">
     <h4>{price ?? "--"} BNB</h4>
     <Link className="zuzu-card-btn bg-success-300" to={`/nft/${marketItemId}`}>
      Buy
     </Link>
    </div>
   </div>
  </div>
 );
};

export default SlideItem;
