import React from "react";

import { useNFT } from "../../context/NFTContext";
import { Link } from "../Link";
import SlideItem from "./Common/SlideItem";
const CollectionSection = () => {
 const { data } = useNFT();
 return (
  <div
   className="zuzu-hero-section3"
   style={{ backgroundColor: "#13111a", overflow: "hidden" }}
  >
   <div className="container">
    <div
     className="zuzu-hero-content zuzu-hero-content2 zuzu-dark-content"
     style={{ paddingBottom: "70px" }}
    >
     <h1 className="wow fadeInUpX" data-wow-delay="0s">
      Platform for buying and selling NFTs suitable for Pro Traders
     </h1>
     <p className="wow fadeInUpX" data-wow-delay="0.25s">
      We provide an official long-term rental platform for cryptocurrency users.
      It plans to use blockchain technology to ensure a secure, seamless rental
      experience.
     </p>
     <div
      className="zuzu-hero-btn-wrap justify-content-center wow fadeInUpX"
      data-wow-delay="0.40s"
     >
      <Link className="zuzu-btn  gradient rounded-pill" to="/collections">
       Explore Collections
      </Link>
     </div>
    </div>
   </div>
   <div className="slide-container">
    <div className="sb-slide">
     {data.map((item, index) => (
      <SlideItem key={index} data={item} />
     ))}
    </div>
   </div>
  </div>
 );
};

export default CollectionSection;
