import React from "react";
import BlockChainSubcribeSection from "../BlockChain/SubcribeSection";

const SubcribeSection = () => {
 return (
  <div className=" bg-[#000000] zuzu-section-padding5">
   <BlockChainSubcribeSection />
  </div>
 );
};

export default SubcribeSection;
