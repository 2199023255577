import React from "react";

const SlideItem = (props) => {
 return (
  <div className="zuzu-testimonial-card">
   <h4>{props.title}</h4>
   <p>{props.des}</p>
   <div className="zuzu-testimonial-author">
    <div className="zuzu-testimonial-author-wrap">
     <div className="zuzu-testimonial-author-thumb">
      <img src={props.image} alt="" />
     </div>
     <div className="zuzu-testimonial-author-data">
      <h5>{props.name}</h5>
      <span>{props.email}</span>
     </div>
    </div>
   </div>
  </div>
 );
};

export default SlideItem;
