import Navbar from "../../components/navbar/navbar";

export const BuyTop = () => {
 return (
  <>
   <Navbar
    fix={false}
    headerClassname="bg-[rgba(56,35,93,0.2)] backdrop-blur-[25px] !absolute !z-40"
   />
  </>
 );
};
