import { useAccount } from "fuhi-web3ts";
import { AddToken } from "./AddToken";
import { Balance } from "./Balance";
import { BuyMethod } from "./BuyMethod";
import { Form } from "./Form";
import { Referral } from "./Referral";

export const WalletConnected = () => {
 const { account } = useAccount();
 return (
  <>
   <div className="relative flex justify-between items-end gap-10">
    <div>
     <div className="text-base leading-[16px] md:text-xl md:leading-[20px]">
      My Wallet
     </div>
     <div className="flex items-center mt-[8px]">
      <div
       className="text-xs leading-[12px] text-primary-54 md:text-sm md:leading-[14px] truncate"
       title={account}
      >
       {account.slice(0, 6) + "..." + account.slice(-6)}
      </div>
     </div>
    </div>
    <Balance />
   </div>
   <div className="h-[1px] bg-white my-[16px] md:my-[24px]" />
   <BuyMethod />
   <div className="mt-[16px] lg:mt-[24px] flex flex-col justify-center ">
    <div className="flex justify-center items-center bg-[rgba(255,255,255,0.19)] rounded-[12px] h-[72px] md:h-[86px] lg:h-[87px] xl:h-[86px]">
     <Referral />
     <div className="w-[1px] bg-white h-[24px] my-auto" />
     <AddToken />
    </div>
    <Form />
   </div>
  </>
 );
};
