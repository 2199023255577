import React from "react";

import Accordion from "react-bootstrap/Accordion";
import { ENV } from "../../environment";
import { Link } from "../Link";

const GlobleSection = () => {
 return (
  <>
   <div className="bg-gray-500 position-relative">
    <div className="container">
     <div className="zuzu-section-title">
      <div className="zuzu-default-content sm dark font-syne">
       <h2>Blockchain technology is recognized and trusted globally</h2>
      </div>
     </div>
     <div className="row">
      <div className="col-lg-6">
       <div className="zuzu-map-thumb">
        <img src={"/images/map.png"} alt="" />
        <div className="zuzu-t-user t-user1">
         <img src={"/images/all-img/v3/t_1.png"} alt="" />
        </div>
        <div className="zuzu-t-user t-user2">
         <img src={"/images/all-img/v3/t_2.png"} alt="" />
        </div>
        <div className="zuzu-t-user t-user3">
         <img src={"/images/all-img/v3/t_3.png"} alt="" />
        </div>
        <div className="zuzu-t-user t-user4">
         <img src={"/images/all-img/v3/t_4.png"} alt="" />
        </div>
       </div>
      </div>
      <div className="col-lg-5 offset-lg-1">
       <div className="zuzu-testimonial-content-v7">
        <div className="zuzu-testimonial-rating">
         <ul>
          <li>
           <img src={"/images/star3.svg"} alt="" />
          </li>
          <li>
           <img src={"/images/star3.svg"} alt="" />
          </li>
          <li>
           <img src={"/images/star3.svg"} alt="" />
          </li>
          <li>
           <img src={"/images/star3.svg"} alt="" />
          </li>
          <li>
           <img src={"/images/star3.svg"} alt="" />
          </li>
         </ul>
        </div>
        <p>
         Blockchain Wallet is my first choice because their team is very
         respectable and on the other hand it is always reliable and safe for
         users' funds.
        </p>
        <p>
         With the use of 5G technology, the ability to interact between users
         and Blockchain will be greatly enhanced.”
        </p>
        <h5>Karen Lynn</h5>
        <span>Founder @ Company</span>
       </div>
      </div>
     </div>
    </div>
    <div className="zuzu-hero-box box3">
     <img src="/images/box.png" alt="" />
    </div>
    <div className="zuzu-hero-box box4">
     <img src="/images/box.png" alt="" />
    </div>
   </div>

   <div className="section bg-gray-500 zuzu-section-padding">
    <div className="container">
     <div className="row">
      <div className="col-lg-6">
       <div className="zuzu-default-content sm dark font-syne">
        <h2>Answers to some common questions about blockchain</h2>
        <p>
         Blockchain is the latest buzzword in the market. Bitcoin is great but
         the potential of the underlying technology is immense.
        </p>
        <div className="zuzu-btn-wrap wow fadeInUpX" data-wow-delay=".10s">
         <Link
          className="zuzu-btn active gradient text-white"
          to={ENV.whitepaper}
         >
          View more
         </Link>
        </div>
       </div>
      </div>
      <div className="col-lg-5 offset-lg-1">
       <div
        className="zuzu--accordion-one accordion-two accordion-six"
        id="accordionExample2"
       >
        <Accordion defaultActiveKey="0" flush>
         <Accordion.Item eventKey="0">
          <Accordion.Header>
           What do you know about Blockchain?{" "}
          </Accordion.Header>
          <Accordion.Body>
           Blockchain is a decentralized distributed database of immutable
           records. This technology was discovered with the invention of Bitcoin
           (the first cryptocurrency).
          </Accordion.Body>
         </Accordion.Item>
         <Accordion.Item eventKey="2">
          <Accordion.Header>
           Why is Blockchain a trustworthy method?
          </Accordion.Header>
          <Accordion.Body>
           Blockchain is a trusted method because it is decentralized, meaning
           no single entity controls it, and it provides transparency through a
           public ledger that all participants can verify.
          </Accordion.Body>
         </Accordion.Item>
         <Accordion.Item eventKey="3">
          <Accordion.Header>
           What are the properties of Blockchain?
          </Accordion.Header>
          <Accordion.Body>
           Blockchain's characteristics include decentralization, eliminating
           the need for a central authority; transparency, with a public ledger
           visible to all participants; and immutable, ensuring transactions
           cannot be altered once recorded.
          </Accordion.Body>
         </Accordion.Item>
        </Accordion>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default GlobleSection;
