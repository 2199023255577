import React, { useEffect, useState } from "react";
import Loading from "../Common/loading";
import CollectionCards from "./CollectionCards";
import CollectionHeader from "./CollectionHeader";

const Collections = () => {
 const [isLoading, setLoading] = useState(true);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <CollectionHeader />
   <CollectionCards />
  </>
 );
};

export default Collections;
