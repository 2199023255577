import { useData } from "fuhi-web3ts";
import React from "react";

import Accordion from "react-bootstrap/Accordion";

const QuestionSection = (props) => {
 const { data } = useData({
  path: "/data/faqs.json",
  default: { left: [], right: [] },
 });
 return (
  <div
   id="faqs"
   className="section zuzu-section-padding2 bg-cover bg-center bg-no-repeat bg-fixed "
   style={{ backgroundImage: "url('/images/bg-faq.png')" }}
  >
   <div className="container">
    <div className="zuzu-section-title">
     <div className="zuzu-default-content large">
      {props.titleContent}
      <h2 style={{ color: "#fff", ...props.style }}>{props.title}</h2>
     </div>
    </div>
    <div
     className="zuzu--accordion-one accordion-three zuzu-faq-dark"
     id="accordionExample3"
    >
     <div className="row">
      <div className="col-lg-6">
       <Accordion defaultActiveKey="0" flush style={{ color: "#fff" }}>
        {data.left?.map((faq, idx) => (
         <Accordion.Item eventKey={idx} key={idx}>
          <Accordion.Header>{faq.title}</Accordion.Header>
          <Accordion.Body>{faq.description}</Accordion.Body>
         </Accordion.Item>
        ))}{" "}
       </Accordion>
      </div>
      <div className="col-lg-6">
       <Accordion style={{ color: "#fff" }}>
        {data.right?.map((faq, idx) => (
         <Accordion.Item eventKey={idx} key={idx}>
          <Accordion.Header>{faq.title}</Accordion.Header>
          <Accordion.Body>{faq.description}</Accordion.Body>
         </Accordion.Item>
        ))}
       </Accordion>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default QuestionSection;
