import React, { useEffect, useState } from "react";

import Loading from "../Common/loading";
import BenifitSection from "./BenifitSection/BenifitSection";
import ContentSection from "./ContentSection/ContentSection";
import CounterSection from "./CounterSection/CounterSection";
import CurrentcySection from "./CurrentcySection/CurrentcySection";
import ExperienceSection from "./ExperienceSection/ExperienceSection";
import InstallSection from "./InstallSection/InstallSection";
import SlideSection from "./SlideSection/SlideSection";
import Header from "./header/header";

const CryptoCard = () => {
 const [isLoading, setLoading] = useState(true);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <Header />
   <CounterSection />
   <BenifitSection />
   <ContentSection />
   <SlideSection />
   <ExperienceSection />
   <CurrentcySection />
   <InstallSection />
  </>
 );
};

export default CryptoCard;
