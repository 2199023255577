import React from "react";
import { MarketList } from "../../MarketList";

const ContentSection = () => {
 return (
  <div className="section bg-gray-500 zuzu-section-padding-bottom">
   <div className="container">
    <div className="zuzu-section-title">
     <div className="zuzu-default-content dark large font-cabin">
      <h2 className="letter-sp-s2">
       Secure solution for digital assets and NFT assets
      </h2>
      <p>
       With NFTG, you can buy, sell, and trade cross-platform NFTs at
       competitive prices and store them securely in one convenient place.
      </p>
     </div>
    </div>
    <div className="row">
     <div className="col-lg-4 col-md-6">
      <div className="zuzu-iconbox-wrap5">
       <div className="zuzu-iconbox-icon5">
        <img src={"/images/icon1.png"} alt="" />
       </div>
       <div className="zuzu-iconbox-data5">
        <h5>Trade NFTs in a simple way</h5>
       </div>
      </div>
     </div>
     <div className="col-lg-4 col-md-6">
      <div className="zuzu-iconbox-wrap5">
       <div className="zuzu-iconbox-icon5">
        <img src={"/images/icon2.png"} alt="" />
       </div>
       <div className="zuzu-iconbox-data5">
        <h5>Buy and sell properties in many countries around the world</h5>
       </div>
      </div>
     </div>
     <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
      <div className="zuzu-iconbox-wrap5">
       <div className="zuzu-iconbox-icon5">
        <img src={"/images/icon3.png"} alt="" />
       </div>
       <div className="zuzu-iconbox-data5">
        <h5>Good incentives and lots of competition</h5>
       </div>
      </div>
     </div>
    </div>
    <div
     className="zuzu-chart-thumb wow fadeInUpX max-w-5xl mx-auto"
     data-wow-delay="0.1s"
    >
     <MarketList />
    </div>
   </div>
  </div>
 );
};

export default ContentSection;
