import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumberFormater, useBuyPresale } from "fuhi-web3ts";
import { useState } from "react";
import { ENV } from "../../../../environment";
import { useBuy } from "../../BuyContext";
import { FormInput } from "./FormInput";
export const Form = () => {
 const { fromValue, toValue, setFromValue, balanceToken, buy, isAllowBuy } =
  useBuyPresale();

 const [isBuying, setIsBuying] = useState(false);
 const { exchangeRate, symbol } = useBuy();
 const handleBuy = async () => {
  try {
   setIsBuying(true);
   await buy();
  } catch (error) {
  } finally {
   setIsBuying(false);
  }
 };
 return (
  <div className="flex flex-col gap-4">
   <div className="mt-12 flex justify-center items-center bg-[rgba(255,255,255,0.19)] rounded-[12px] h-[72px] md:h-[86px] lg:h-[87px] xl:h-[86px] gap-10 px-4 py-2">
    <FormInput
     value={fromValue}
     placeholder={`0.00 ${symbol}`}
     onChange={setFromValue}
     customElement={
      <span>
       1 {symbol} = {NumberFormater.format(exchangeRate)} {ENV.tokenSymbol}
      </span>
     }
    />
    <div className="w-[1px] bg-white h-[24px] my-auto" />
    <FormInput
     value={toValue}
     customElement={
      <span>
       Balance: {NumberFormater.format(balanceToken)} {ENV.tokenSymbol}
      </span>
     }
    />
   </div>
   <div className="flex justify-center">
    <button
     className="text-xs leading-[14px] md:text-base md:leading-[16px] font-medium h-[28px]  px-20 text-center text-primary-100 rounded-full select-none bg-gradient-to-tr  from-[#4d47e3] to-[#33ebca] md:h-[48px] hover:bg-gradient-to-br  text-white disabled:cursor-not-allowed   "
     disabled={!isAllowBuy || isBuying}
     onClick={handleBuy}
    >
     <span>Buy </span>
     {isBuying && (
      <FontAwesomeIcon className="ml-2 animate-spin" icon={faSpinner} />
     )}
    </button>
   </div>
  </div>
 );
};
