import { NumberFormater, myToast } from "fuhi-web3ts";
import { useState } from "react";

export const StakingJob = ({ value }) => {
 return (
  <>
   <div className="shrink-0 bg-white/40 h-[1px] my-9" />
   <div>
    <div className="flex items-center partner-item flex-wrap ">
     <div className="w-full sm:flex-1 sm:mx-7">
      <div className="flex justify-between items-center mr-4 text-2xl font-bold">
       <div className="flex items-center">
        {value.name}
        <div className="flex font-normal ml-4 gap-2" />
       </div>
       <div className="pointer-events-auto flex items-center font-normal">
        <OverlayTrigger
         overlay={
          <div className="flex flex-col gap-y-4 px-4 py-2">
           <div className="flex flex-col">
            <span className="text-white/80 text-xs">Minimum Staked Amount</span>
            <span className="text-white text-sm font-medium">
             {Number.isInteger(value.minStake)
              ? NumberFormater.format(value.minStake)
              : "--"}
            </span>
           </div>
           <div className="flex flex-col">
            <span className="text-white/80 text-xs">Maximum Staked Amount</span>
            <span className="text-white text-sm font-medium">
             {Number.isInteger(value.minStake)
              ? NumberFormater.format(value.maxStake)
              : "--"}
            </span>
           </div>
           <div className="flex flex-col">
            <span className="text-white/80 text-xs"> Distribution Rules</span>
            <p className="text-white text-sm font-medium">{value.detail}</p>
           </div>
          </div>
         }
         placement="top"
        >
         <button className="text-white text-sm hover:text-primary">
          View Details
         </button>
        </OverlayTrigger>
       </div>
      </div>
      <div className="flex items-center">
       <div className="text-lg text-white/80 sm:w-[580px] w-[240px] mt-4 mb-4 text-ellipsis text-nowrap overflow-hidden">
        {value.description}
       </div>
      </div>
      <button
       className="zuzu-btn"
       onClick={() => myToast.toast.info("Staking feature coming soon!")}
      >
       Stake Now
      </button>
     </div>
     <div className="h-[172px] sm:h-[208px] justify-center bg-gray-900/40 items-center relative overflow-hidden w-full sm:w-[316px]  mt-6 sm:mt-0 rounded-[16px]">
      <div
       className="absolute h-[90px] left-1/2 bottom-0 text-[92px] font-mona font-extrabold opacity-[0.02] leading-[90px]"
       style={{ transform: "translateX(-50%)" }}
      >
       {value.symbol}
      </div>
      <div className="flex flex-col items-center h-full justify-center">
       <img
        alt=""
        loading="lazy"
        width={72}
        height={72}
        decoding="async"
        data-nimg={1}
        src={value.logo}
        style={{ color: "transparent" }}
       />
       <div className="mt-4 font-mona text-4xl font-extrabold">
        <span className="mr-0.5">-- </span>
        {value.symbol}
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

function OverlayTrigger({ overlay, placement, children }) {
 const [show, setShow] = useState(false);

 const handleMouseEnter = () => setShow(true);
 const handleMouseLeave = () => setShow(false);

 return (
  <>
   <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
    {children}
   </div>
   {show && (
    <Tooltip placement={placement} className="z-[100]">
     {overlay}
    </Tooltip>
   )}
  </>
 );
}

function Tooltip({ placement, className, children }) {
 return (
  <div className={className}>
   <div className="absolute p-2 rounded-md bg-black text-white w-fit max-w-sm">
    {children}
   </div>
   <div
    className="absolute"
    style={{
     border: "solid 1px white",
     width: "0",
     height: "0",
     top: "50%",
     transform: "translateY(-50%)",
     position: "absolute",
     [`${placement}`]: "center",
     zIndex: 100,
    }}
   >
    <div
     style={{
      transform: "translate(-50%, -50%) rotate(45deg)",
     }}
     className="w-1 h-1 bg-white absolute"
    ></div>
   </div>
  </div>
 );
}
