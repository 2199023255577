import React, { useEffect, useState } from "react";
import Loading from "../Common/loading";
import CounterSection from "../CryptoCard/CounterSection/CounterSection";
import QuestionSection from "../NFTPlatform/QuestionSection";
import AboutHeader from "./AboutHeader";
import CoreValueSection from "./CoreValueSection";
import LearnSection from "./LearnSection";
import MeetOurTeam from "./MeetOurTeam";
import OurMissionSection from "./OurMissionSection";

const AboutUs = () => {
 const questStyle = {
  fontSize: "48px",
  lineHeight: "60px",
  marginBottom: "15px",
 };

 const [isLoading, setLoading] = useState(true);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <AboutHeader />
   <LearnSection />
   <CounterSection />
   <OurMissionSection />
   <CoreValueSection />
   <MeetOurTeam />
   <QuestionSection
    title={
     "What do you know about our company? Here are some related questions"
    }
    style={questStyle}
    titleContent={<h5>Frequently asked questions</h5>}
   />
  </>
 );
};

export default AboutUs;
