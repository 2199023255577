import React, { useMemo } from "react";
import { useNFT } from "../../context/NFTContext";
import { Link } from "../Link";
import { NFTItem } from "../NFTItem/NFTItem";
const CardSection = () => {
 const { data: fullData } = useNFT();
 const data = useMemo(() => {
  return fullData.length > 8 ? fullData.slice(0, 8) : fullData;
 }, [fullData]);

 return (
  <div className="section bg-gray-800 zuzu-section-padding2">
   <div className="container">
    <div className="zuzu-section-title">
     <div className="zuzu-default-content large">
      <h2 style={{ color: "#fff" }}>
       Explore our best available for digital art collecting
      </h2>
     </div>
    </div>
    <div className="row">
     {data.map((nft, idx) => (
      <NFTItem value={nft} key={idx} />
     ))}
    </div>
    <div className="zuzu-center-btn">
     <Link className="zuzu-btn btn-pink rounded-pill" to="/collections">
      View All Collections
     </Link>
    </div>
   </div>
  </div>
 );
};

export default CardSection;
