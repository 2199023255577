import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNFT } from "../../context/NFTContext";

const context = createContext({});

export const NFTDetailProvider = (p) => {
 const [value, setValue] = useState();
 const { data: fullData } = useNFT();
 const { marketItemId } = useParams();
 useEffect(() => {
  if (marketItemId && fullData?.length) {
   const _value = fullData.find((f) => f.marketItemId == marketItemId);
   setValue(_value);
  }
 }, [marketItemId, fullData]);
 return (
  <context.Provider
   value={{
    value,
   }}
  >
   {p.children}
  </context.Provider>
 );
};

export const useNFTDetail = () => useContext(context);
