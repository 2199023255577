import {
 faArrowDown,
 faArrowUp,
 faChevronLeft,
 faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { cmccService } from "../../services/CMCC.js";
const formatter = new Intl.NumberFormat("en-US", {
 style: "currency",
 currency: "USD",
});
const LIMIT = 10;
export const MarketList = (props) => {
 const { className } = props;
 const [isLoading, setIsLoading] = useState(false);
 const [page, setPage] = useState(0);
 const [datas, setDatas] = useState([]);

 useEffect(() => {
  loadList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [page]);

 function loadList(props = {}) {
  let { emptyArray } = props;
  emptyArray = emptyArray ?? true;
  setIsLoading(true);
  if (emptyArray) setDatas([]);
  cmccService
   .getMany({ page, limit: LIMIT })
   .then(setDatas)
   .finally(() => {
    setIsLoading(false);
   });
 }

 return (
  <div className="overflow-x-auto bg-[#14151b] rounded-lg w-full px-4 py-4 overflow-hidden">
   <h5 className="text-white">Market Table</h5>
   <table className={`w-full table-auto ${className || ""}`}>
    <thead>
     <tr className="bg-[#14151b] text-slate-600 border-b border-slate-600">
      {columns.map((column) => (
       <th
        key={column.title}
        className="p-4 text-xs w-20"
        style={{
         width: column.width,
         textAlign: column.textAlign,
        }}
       >
        {column.title}
       </th>
      ))}
     </tr>
    </thead>
    <tbody className="bg-[#14151b]">
     {datas?.length === 0 &&
      new Array(LIMIT).fill(0).map((_, idx) => (
       <tr key={idx}>
        <td className={"h-[88px] w-full "} colSpan={columns.length + 1}>
         <div className="h-10 animate-pulse bg-slate-800 rounded-lg w-full"></div>
        </td>
       </tr>
      ))}
     {datas.map((data, idx) => {
      const index = idx + 1 + page * 10;
      return (
       <tr key={data.id} className={""}>
        {columns.map((column) => (
         <Cell key={column.title} style={{ textAlign: column.textAlign }}>
          {column.getValue ? column.getValue(data) : index}
         </Cell>
        ))}
       </tr>
      );
     })}
    </tbody>
   </table>
   <div className="flex  justify-center space-x-4">
    <button
     className="p-1 bg-gray-800 text-white  rounded-full aspect-square text-sm w-8 flex justify-center items-center h-8 hover:brightness-125 disabled:cursor-not-allowed disabled:opacity-50"
     onClick={() => page > 0 && setPage(page - 1)}
     disabled={page === 0}
    >
     <FontAwesomeIcon icon={faChevronLeft} />
    </button>
    <button
     className="p-1 bg-gray-800 text-white  rounded-full aspect-square text-sm w-8 flex justify-center items-center h-8 hover:brightness-125 disabled:cursor-not-allowed disabled:opacity-50"
     onClick={() => page < 15 && setPage(page + 1)}
     disabled={page === 15}
    >
     <FontAwesomeIcon icon={faChevronRight} />
    </button>
   </div>
  </div>
 );
};

export function Cell(props) {
 return (
  <td
   style={props.style}
   className={` text-neutral-200 text-sm px-2 py-8 ${props.className || ""}`}
  >
   {props.children}
  </td>
 );
}

const columns = [
 {
  title: "Assets",
  getValue: (item) => (
   <div className="pl-6 flex gap-x-2 items-center font-bold">
    <img
     className="h-6"
     src={
      "https://s2.coinmarketcap.com/static/img/coins/64x64/" + item.id + ".png"
     }
     alt=""
    />
    <span>{item.symbol}</span>
    <span className="text-slate-600">{item.name}</span>
   </div>
  ),
 },
 {
  title: "Last Price",
  getValue: (item) => formatter.format(item.quote.USD.price),
  textAlign: "right",
 },
 {
  title: "Market cap",
  getValue: (item) => (
   <div className="pr-6">{formatter.format(item.quote.USD.market_cap)}</div>
  ),
  textAlign: "right",
 },
 {
  title: "Volume 24h",
  getValue: (item) => (
   <div className="pr-6">{formatter.format(item.quote.USD.volume_24h)}</div>
  ),
  textAlign: "right",
 },
 {
  title: "Change",
  getValue: (item) => {
   const percent = Math.round(item.quote.USD.percent_change_24h * 100) / 100;
   return (
    <>
     <div
      className={
       (percent >= 0 ? "text-[#30daa7]" : "text-[#6045bf]") +
       " text-center flex items-center gap-x-2 justify-end"
      }
     >
      <FontAwesomeIcon icon={percent >= 0 ? faArrowUp : faArrowDown} />
      {Math.abs(percent)}%
     </div>
    </>
   );
  },
  textAlign: "right",
 },
];
