import { NumericFormat } from "react-number-format";
export const FormInput = ({ value, onChange, placeholder, customElement }) => {
 return (
  <div className="flex flex-col flex-1 items-center cursor-pointer ">
   <div className="flex items-center text-primary-100 text-base leading-[16px] md:text-xl md:leading-[20px] lg:text-2xl lg:leading-[24px]">
    <NumericFormat
     thousandSeparator
     placeholder={placeholder}
     value={value || ""}
     className="border-none w-full bg-transparent text-white text-center"
     onValueChange={(e) => onChange?.(e.floatValue || 0)}
     disabled={!Boolean(onChange)}
    />
   </div>
   <div className="text-primary-54 mt-[8px] text-xs leading-[12px] md:text-sm md:leading-[14px]">
    {customElement}
   </div>
  </div>
 );
};
