import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "../../components/Link";
import { useBuy } from "./BuyContext";

export const Partners = () => {
 const { CONFIG } = useBuy();
 const [swiper, setSwiper] = useState();
 return (
  <div className="mt-[16px] lg:mt-[24px] ">
   <div className="mb-[12px] text-xs leading-[12px] font-medium text-primary-80 md:text-sm md:leading-[14px] lg:font-base lg:leading-[16px]">
    Trade with our partners
   </div>
   <div className="h-[48px] md:h-[72px] relative">
    <Swiper
     loop
     slidesPerView={{ 640: 2, 320: 1 }}
     spaceBetween={40}
     onSwiper={setSwiper}
    >
     {CONFIG.partners?.map((partner, index) => (
      <SwiperSlide key={index}>
       <div className="flex justify-center items-center bg-[rgba(255,255,255,0.19)] rounded-[12px] h-[48px] md:h-[72px] cursor-pointer">
        <Link className="w-full h-full" to={partner.url}>
         <div className="border-[1px] border-solid border-transparent md:hover:border-[#B084E9] w-full h-full flex justify-center items-center rounded-[12px]">
          <img
           src={partner.logo}
           className="h-[24px] md:h-[32px]"
           alt="QuickPerps"
          />
         </div>
        </Link>
       </div>
      </SwiperSlide>
     ))}
    </Swiper>
    <svg
     width={16}
     height={16}
     viewBox="0 0 16 16"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
     className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] absolute top-1/2 left-5 -translate-y-1/2 z-10 cursor-pointer"
     onClick={() => swiper?.slidePrev()}
    >
     <mask
      id="mask0_1620_6933"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
      style={{ maskType: "alpha" }}
     >
      <rect
       x={16}
       y={16}
       width={16}
       height={16}
       transform="rotate(180 16 16)"
       fill="#D9D9D9"
      />
     </mask>
     <g mask="url(#mask0_1620_6933)">
      <path d="M7.55033 8.00002L9.60033 5.95002L8.66699 5.00002L5.66699 8.00002L8.66699 11L9.60033 10.05L7.55033 8.00002ZM8.00033 1.33335C8.92255 1.33335 9.78921 1.50836 10.6003 1.85835C11.4114 2.20835 12.117 2.68335 12.717 3.28335C13.317 3.88335 13.792 4.58891 14.142 5.40002C14.492 6.21113 14.667 7.0778 14.667 8.00002C14.667 8.92224 14.492 9.78891 14.142 10.6C13.792 11.4111 13.317 12.1167 12.717 12.7167C12.117 13.3167 11.4114 13.7917 10.6003 14.1417C9.78921 14.4917 8.92255 14.6667 8.00033 14.6667C7.0781 14.6667 6.21144 14.4917 5.40033 14.1417C4.58921 13.7917 3.88366 13.3167 3.28366 12.7167C2.68366 12.1167 2.20866 11.4111 1.85866 10.6C1.50866 9.78891 1.33366 8.92224 1.33366 8.00002C1.33366 7.0778 1.50866 6.21113 1.85866 5.40002C2.20866 4.58891 2.68366 3.88335 3.28366 3.28335C3.88366 2.68335 4.58921 2.20835 5.40033 1.85835C6.21144 1.50836 7.0781 1.33335 8.00033 1.33335Z" />
     </g>
    </svg>{" "}
    <svg
     width={16}
     height={16}
     viewBox="0 0 16 16"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
     className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] absolute right-5 top-1/2 -translate-y-1/2 z-10 cursor-pointer"
     onClick={() => swiper?.slideNext()}
    >
     <mask
      id="mask0_1620_6934"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
      style={{ maskType: "alpha" }}
     >
      <rect width={16} height={16} fill="#D9D9D9" />
     </mask>
     <g mask="url(#mask0_1620_6934)">
      <path d="M8.45016 7.99999L6.40016 10.05L7.3335 11L10.3335 7.99999L7.3335 4.99999L6.40016 5.94999L8.45016 7.99999ZM8.00016 14.6667C7.07794 14.6667 6.21127 14.4917 5.40016 14.1417C4.58905 13.7917 3.8835 13.3167 3.2835 12.7167C2.6835 12.1167 2.2085 11.4111 1.8585 10.6C1.5085 9.78888 1.3335 8.92222 1.3335 7.99999C1.3335 7.07777 1.5085 6.21111 1.8585 5.39999C2.2085 4.58888 2.6835 3.88333 3.2835 3.28333C3.8835 2.68333 4.58905 2.20833 5.40016 1.85833C6.21127 1.50833 7.07794 1.33333 8.00016 1.33333C8.92238 1.33333 9.78905 1.50833 10.6002 1.85833C11.4113 2.20833 12.1168 2.68333 12.7168 3.28333C13.3168 3.88333 13.7918 4.58888 14.1418 5.39999C14.4918 6.21111 14.6668 7.07777 14.6668 7.99999C14.6668 8.92222 14.4918 9.78888 14.1418 10.6C13.7918 11.4111 13.3168 12.1167 12.7168 12.7167C12.1168 13.3167 11.4113 13.7917 10.6002 14.1417C9.78905 14.4917 8.92238 14.6667 8.00016 14.6667Z" />
     </g>
    </svg>
   </div>
  </div>
 );
};
