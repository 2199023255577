import { useConnect } from "fuhi-web3ts";

export const ConnectWalletButton = () => {
 const { openWeb3: connect, isConnected } = useConnect();
 if (isConnected) return <></>;
 return (
  <button
   type="button"
   className="text-xs leading-[14px] md:text-base md:leading-[16px] font-medium h-[28px] px-[12px] md:px-[20px] text-center text-primary-100 rounded-full select-none bg-gradient-to-tr  from-[#4d47e3] to-[#33ebca] hover:bg-gradient-to-br md:h-[48px]  text-white"
   onClick={connect}
  >
   Connect Wallet
  </button>
 );
};
