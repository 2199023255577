import React from "react";

const BenifitSection = () => {
 return (
  <>
   <div className="section bg-gray-500 zuzu-section-padding">
    <div className="container">
     <div className="row">
      <div className="col-lg-6">
       <div className="zuzu-thumb wow fadeInUpX" data-wow-delay="0.2s">
        <img src={"/images/card-thumb1.png"} alt="" data-aos="fade-up" />
       </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center">
       <div className="zuzu-default-content dark large font-cabin">
        <h2 className="letter-sp-s2">Get rewards with each feature used</h2>
        <p>
         Each individual participating in using the features at NFTG will
         receive rewards depending on the level of use. For features used with
         5G core technology at Blockchain, it will bring the opportunity to own
         rewards with practical value that can be applied in life. For features
         used at Web3, rewards can be circulated on the Blockchain.
        </p>
       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="section bg-gray-500 zuzu-section-padding-bottom">
    <div className="container">
     <div className="row">
      <div className="col-lg-6 order-lg-2 d-flex align-items-end">
       <div
        className="zuzu-bank-card-wrap wow fadeInUpX"
        data-wow-delay="0.1s"
        data-aos="fade-up"
        data-aos-delay="300"
       >
        <div className="zuzu-bank-card1">
         <img src={"/images/card03.png"} alt="" data-aos="fade-up" />
        </div>
        <div className="zuzu-bank-card2">
         <img src={"/images/card04.png"} alt="" />
        </div>
       </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center">
       <div className="zuzu-default-content dark large font-cabin">
        <h2 className="letter-sp-s2">Manage your payments at Web3</h2>
        <p>
         At NFTG, payment methods are promoted by allowing the use of payment
         cards and crypto cards.
        </p>
        <div className="zuzu-icon-list dark">
         <ul>
          <li>
           <img src={"/images/check2.svg"} alt="" />
           The easiest way to spend cryptocurrency worldwide
          </li>
          <li>
           <img src={"/images/check2.svg"} alt="" />
           World-class security to keep your crypto safe
          </li>
          <li>
           <img src={"/images/check2.svg"} alt="" />
           Easily choose which cryptocurrency you spend{" "}
          </li>
         </ul>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default BenifitSection;
