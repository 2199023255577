import React from "react";
import { Link } from "../Link";

const AboutHeader = () => {
 return (
  <div className="zuzu-breadcrumb-section bg-gray-800 dark">
   <div className="container">
    <div
     className="breadcrumbs zuzu-inner-padding contact-hd-bg"
     style={{
      backgroundImage: `url('/shape/dots.png')`,
     }}
    >
     <h1 className="wow fadeInUpX" data-wow-delay="0s" data-aos="fade-up">
      About Us
     </h1>
     <nav
      aria-label="breadcrumb"
      className="wow fadeInUpX"
      data-wow-delay="0.15s"
     >
      <ol className="breadcrumb" data-aos="fade-up" data-aos-delay="300">
       <li className="breadcrumb-item">
        <Link to="/">Home</Link>
       </li>
       <li className="breadcrumb-item active" aria-current="page">
        {" "}
        About Us
       </li>
      </ol>
     </nav>
    </div>
   </div>
  </div>
 );
};

export default AboutHeader;
