import React, { useEffect, useState } from "react";
import Loading from "../Common/loading";
import FeatureSection from "./FeatureSection";
import GlobleSection from "./GlobleSection";
import HeroSection from "./HeroSection";
import MidSection from "./MidSection";
import SubcribeSection from "./SubcribeSection";
import VideoSection from "./VideoSection";

const BlockChain = () => {
 const [isLoading, setLoading] = useState(true);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <HeroSection />
   <FeatureSection />
   <MidSection />
   <VideoSection />
   <GlobleSection />
   <SubcribeSection />
  </>
 );
};

export default BlockChain;
