import React from "react";

const LearnSection = () => {
 return (
  <div className="section zuzu-section-padding bg-gray-500">
   <div className="container">
    <div className="row">
     <div className="col-xl-6 col-lg-6">
      <div
       className="zuzu-thumb position-relative wow fadeInUpX"
       data-aos="fade-up"
      >
       <img src={"/aboutus/learnaboutus.png"} alt="" />
      </div>
     </div>
     <div className="col-xl-5 offset-xl-1 col-lg-6 d-flex align-items-center">
      <div className="zuzu-default-content dark">
       <h5>
        {" "}
        <strong>Learn about us</strong>{" "}
       </h5>
       <h2 className="font-black">
        The story behind the beginning of our company
       </h2>
       <p>
        NFTG is an innovative company working to launch an NFT project focused
        on documenting and celebrating the history of blockchain.
       </p>
       <p>
        We have a diverse team of 30+ blockchain developers who can work across
        multiple environments.
       </p>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default LearnSection;
