const formater = new Intl.NumberFormat("en-US");

export class NumberFormater {
 static format(value) {
  return formater.format(value);
 }

 static roundUp(num, precision = 2) {
  return +num.toFixed(precision);
 }
}

export class MoneyFormater {
 static format(value) {
  if (value >= 1) return "$" + NumberFormater.format(value);
  return "$" + NumberFormater.roundUp(value, 4);
 }
}

export class BigIntFormater {
 static toNumber(value) {
  if (value === undefined) return 0;
  return Number(value) / 1e18;
 }

 static format(value) {
  return formater.format(this.toNumber(value));
 }

 static roundUp(num, precision = 8) {
  return +this.toNumber(num).toFixed(precision);
 }

 static toDate(value) {
  if (value === undefined) return undefined;
  if (Number(value) === 0) return undefined;
  return new Date(Number(value) * 1000);
 }
}

export class DateFormater {
 static format(date) {
  if (!date) return "";
  return date.toLocaleDateString("en-US", {
   year: "numeric",
   month: "numeric",
   day: "numeric",
  });
 }

 static formatDateTime(date) {
  if (!date) return "";
  return date.toLocaleString("en-US", {
   year: "numeric",
   month: "numeric",
   day: "numeric",
   hour: "numeric",
   minute: "numeric",
   hour12: false,
  });
 }
}
