import { useData } from "fuhi-web3ts";
import React from "react";
import CoreValueItem from "../AboutUs/CoreValueItem";

const FeatureSection = () => {
 const { data } = useData({ path: "/data/corevaluedata.json", default: [] });
 return (
  <div className="section bg-gray-500 zuzu-section-padding3">
   <div className="container">
    <div className="zuzu-2column-title">
     <div className="row">
      <div className="col-lg-6">
       <div className="zuzu-default-content sm dark font-syne">
        <h2>Key features that make blockchain more attractive</h2>
       </div>
      </div>
      <div className="col-lg-5 offset-lg-1">
       <div className="zuzu-default-content dark">
        <p>
         Blockchain is a particularly promising and revolutionary technology
         because it helps reduce security risks, prevent fraud, and provide
         transparency in a scalable way.
        </p>
       </div>
      </div>
     </div>
    </div>
    <div className="row">
     {data.map((item, index) => (
      <CoreValueItem data={item} key={index} index={index} />
     ))}
    </div>
   </div>
  </div>
 );
};

export default FeatureSection;
