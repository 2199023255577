import { ENV } from "../../environment";
import { useBuy } from "./BuyContext";
import { Clock } from "./Clock";
import { Partners } from "./Partners";

export const BuyLeftPanel = () => {
 const { CONFIG } = useBuy();
 return (
  <div className="bg-[rgba(0, 0, 0, 0.3)] backdrop-blur-[25px] rounded-[12px] p-[24px] flex-1 w-full">
   <div className="text-base leading-[16px] text-primary-100 md:text-xl md:leading-[20px] lg:text-2xl lg:leading-[24px]">
    {CONFIG.left?.title}
   </div>
   <div className="text-xs leading-[12px] text-primary-54 font-medium mt-[12px] md:text-sm md:leading-[14px] whitespace-nowrap">
    {CONFIG.left?.time}
   </div>
   <div className="h-[1px] bg-white my-[16px] lg:my-[24px]" />
   <div className="flex flex-col md:flex-row lg:!flex-col xl:flex-row md:justify-between gap-y-[24px] md:gap-x-[24px]">
    <Clock />
    <div className="md:flex-1">
     <div className="mb-[12px] text-xs leading-[12px] font-medium text-primary-80 md:text-sm md:leading-[14px] lg:font-base lg:leading-[16px]">
      Presale Distribution
     </div>
     <div className="flex justify-center items-center bg-[rgba(255,255,255,0.19)] rounded-[12px] flex-row md:!flex-col lg:mt-[20px] xl:mt-0 h-[48px] md:h-[80px] lg:h-[86px] xl:h-[112px]">
      <div className="text-base leading-[16px] text-primary-100 md:text-2xl md:leading-[24px] lg:text-[28px] lg:leading-[28px]">
       312,000,000
      </div>
      <div className="text-xs leading-[12px] text-primary-54 md:text-base md:leading-[16px] ml-[4px] md:ml-0 md:mt-[8px]">
       {ENV.tokenSymbol}
      </div>
     </div>
    </div>
   </div>
   <Partners />
  </div>
 );
};
