import { useBuyPresale, useData, useWeb3Ts } from "fuhi-web3ts";
import React, { createContext, useContext, useMemo } from "react";

const context = createContext({});

export const BuyProvider = (p) => {
 const { data: CONFIG } = useData({ default: {}, path: "/data/presale.json" });
 const { buyMetadata } = useWeb3Ts();
 const { method } = useBuyPresale();
 const minBuy = useMemo(() => {
  return method === "bnb" ? buyMetadata.minBuy : buyMetadata.minBuyUSDT;
 }, [method, buyMetadata.minBuy, buyMetadata.minBuyUSDT]);
 const maxBuy = useMemo(() => {
  return method === "bnb" ? buyMetadata.maxBuy : buyMetadata.maxBuyUSDT;
 }, [method, buyMetadata.maxBuy, buyMetadata.maxBuyUSDT]);
 const icon = useMemo(() => {
  return method === "usdt" ? "/images/usdt.png" : "/images/bnb.png";
 }, [method]);

 const symbol = useMemo(() => {
  return method === "usdt" ? "USDT" : "BNB";
 }, [method]);
 const exchangeRate = useMemo(() => {
  return method === "bnb" ? buyMetadata.salePrice : buyMetadata.salePriceUSDT;
 }, [method, buyMetadata.salePrice, buyMetadata.salePriceUSDT]);
 return (
  <context.Provider
   value={{ CONFIG, maxBuy, minBuy, icon, symbol, exchangeRate }}
  >
   {p.children}
  </context.Provider>
 );
};

export const useBuy = () => useContext(context);
