import {
 NumberFormater,
 myToast,
 useAccount,
 useConnect,
 useContractAddress,
} from "fuhi-web3ts";
import React, { useMemo } from "react";
import { useNFT } from "../../context/NFTContext.jsx";
import { ENV } from "../../environment";
import { BigIntFormater, DateFormater } from "../../utils/NumberFormater.js";
import Loading from "../Common/loading";
import BlogHeader from "./BlogHeader";
import BlogLeft from "./BlogLeft";
import { NFTDetailProvider, useNFTDetail } from "./NFTDetailContext";
import UserItem from "./UserItem";

const Inner = () => {
 const { value } = useNFTDetail();
 const { isConnected, connect } = useConnect();
 const { account } = useAccount();
 const { buy } = useNFT();
 const { shortAddress: contractAddress } = useContractAddress();
 const isLoading = useMemo(() => Boolean(value === undefined), [value]);
 const price = useMemo(
  () => (value?.price ? NumberFormater.format(value.price) : "--"),
  [value]
 );
 const handleBuy = () => {
  if (!isConnected) {
   return connect();
  } else {
   buy({ marketItemId: value.marketItemId, price: value.price });
  }
 };
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <BlogHeader />
   <div className="blog-container">
    <BlogLeft />
    <div className="blog-col">
     <div className="blog-r-container">
      <div className="blog-r-hd">
       <div className="blog-r-hd-info">
        <div className="r-hd-title">
         <p>{value.name}</p>
        </div>
       </div>
       <div className="blog-r-hd-sub">
        <div className="hd-sub-icon">
         <div
          className="external-link-icon"
          style={{
           content: `url("/images/external-link.png")`,
          }}
         ></div>
        </div>
        <p>
         Minted on{" "}
         {DateFormater.format(BigIntFormater.toDate(value.createTime))}
        </p>
       </div>
      </div>
      <div className="blog-r-content">
       <div className="r-blog-box-l">
        <div className="blog-box r-content-box01">
         <div className="blog-box-title">
          <p>Buy now</p>
         </div>
         <div className="blog-box-price">
          <p>{price} BNB</p>
         </div>
         {account !== value.owner ? (
          <div className="blog-box-btn" onClick={handleBuy}>
           {<p>{isConnected ? "Buy Now" : "Connect wallet"}</p>}
          </div>
         ) : (
          <p>This NFT is yours already!</p>
         )}
        </div>
        {account !== value.owner && (
         <div className="blog-box r-content-box02">
          <div className="blog-box-title">
           <p>Reserve </p>
          </div>
          <div className="blog-box-price">
           <p>--</p>
          </div>

          <div
           className="blog-box-btn"
           onClick={() =>
            myToast.toast("The Place A Bid feature will be live soon", {
             type: "info",
            })
           }
          >
           <p>Place a bid</p>
          </div>
         </div>
        )}
       </div>
       <div className="r-blog-box-bot">
        <div className="blog-box r-content-box">
         <div className="blog-box-info">
          <div className="blog-box-title">
           <p>Last sold</p>
          </div>
          <div className="blog-box-price">
           <p>{price} BNB</p>
          </div>
         </div>
         <UserItem
          img={"/images/nft-contract-avatar.jpg"}
          title={ENV.tokenSymbol}
          id={contractAddress}
          isFrame={false}
         />
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};
const NFTDetail = () => {
 return (
  <NFTDetailProvider>
   <Inner />
  </NFTDetailProvider>
 );
};
export default NFTDetail;
