import { myToast, useBuyPresale } from "fuhi-web3ts";
import { ENV } from "../../../environment.js";
const methods = [
 {
  method: "bnb",
  name: "BNB",
 },
 {
  method: "usdt",
  name: "USDT",
 },
 {
  method: "mastercard",
  name: "Mastercard",
 },
];
export const BuyMethod = () => {
 const { method, setMethod } = useBuyPresale();
 const handleClick = ({ method: methodName }) => {
  if (ENV.buyMethods?.includes(methodName)) {
   setMethod(methodName);
  } else {
   myToast.toast.warning("Coming soon!");
  }
 };
 return (
  <div className="flex overflow-hidden rounded-[8px]">
   {methods.map((m) => (
    <div
     key={m.method}
     className={`h-[32px] md:h-[40px] lg:h-[48px] flex justify-center items-center  font-semibold cursor-pointer select-none text-xs leading-[12px] md:text-sm md:leading-[14px] lg:text-base lg:leading-[16px] ${
      m.method === method
       ? "bg-gradient-to-tr  from-[#4d47e3] to-[#33ebca] text-primary-100"
       : "bg-[rgba(255,255,255,0.19)] text-primary-54"
     } w-full z-1 hover:bg-gradient-to-tl`}
     onClick={() => handleClick(m)}
    >
     {m.name}
    </div>
   ))}
  </div>
 );
};
