import React from "react";

import { useData } from "fuhi-web3ts";
import CountUp from "react-countup";
const HistorySection = () => {
 const { data } = useData({ path: "/data/roadmap.json", default: [] });
 return (
  <>
   <div className="section bg-gray-800 zuzu-section-padding3">
    <div className="container">
     <div className="zuzu-section-title">
      <div className="zuzu-default-content large dark">
       <h2>Follow Our Development Roadmap</h2>
      </div>
     </div>
     <div
      className="zuzu-iconbox-bg htr-bg"
      style={{
       backgroundImage: `url("/images/shape-dots.png")`,
      }}
     >
      <div className="row">
       {data.map((roadmap, idx) => (
        <div className="col-lg-3 col-md-6">
         <div
          className={`zuzu-iconbox-wrap ${
           idx % 2 === 1 ? "custom_margin_b" : ""
          }wow fadeInUpX`}
          data-wow-delay={`0.${idx + 1}s`}
         >
          <div className="zuzu-iconbox-icon">
           <img src={roadmap.icon} alt="" />
          </div>
          <div className="zuzu-iconbox-data">
           <h4>{roadmap.title}</h4>
           <p>{roadmap.description}</p>
          </div>
         </div>
        </div>
       ))}
      </div>
     </div>
    </div>
   </div>

   <div
    className="section zuzu-section-padding  bg-cover bg-center bg-no-repeat bg-fixed"
    style={{ backgroundImage: "url('/images/bg1.png')" }}
   >
    <div id="zuzu-counter"></div>
    <div className="container">
     <div className="row">
      <div className="col-lg-6 d-lg-flex justify-content-sm-end order-lg-2">
       <div className="zuzu-cartoon-thumb cartoon2">
        <img
         className="wow fadeInUpX"
         data-wow-delay=".10s"
         src={"/images/thumb2.png"}
         alt=""
         style={{ borderRadius: "15px" }}
        />
       </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center">
       <div className="zuzu-default-content large" style={{ color: "#fff" }}>
        <h2 style={{ color: "#fff" }}>That's why we are better than others</h2>
        <p>
         We are the first marketplace that allows users to sell their own NFTs.
         You can buy and sell your NFTs with the best deals here. Place your bid
         and start trading easily without any hassle.
        </p>
        <div className="zuzu-counter-wrap zuzu-counter-wrap4">
         <div
          className="zuzu-counter-data"
          style={{ borderRight: "1px solid rgb(255 255 255 / 10%)" }}
         >
          <h2>
           <span className="zuzu-counter">
            <CountUp end={1.5} duration={2.5} decimals={1} />
           </span>
           <strong>B</strong>
          </h2>
          <p style={{ color: "#fff" }}>
           Over a billion blockchain assets to explore and discover.
          </p>
         </div>
         <div className="zuzu-counter-data">
          <h2>
           <span className="zuzu-counter">
            <CountUp end={4.2} duration={2.5} decimals={1} />
           </span>
           <strong>M</strong>
          </h2>
          <p style={{ color: "#fff" }}>
           More than 3 million digital items are traded through the marketplace.
          </p>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default HistorySection;
