import React from "react";
import CountUp from "react-countup";

const CounterSection = () => {
 return (
  <div className="zuzu-counter-section bg-gray-500">
   <div id="zuzu-counter"></div>
   <div className="container">
    <div className="zuzu-counter-wrap">
     <div className="zuzu-counter-data" data-aos="fade-up">
      <h2>
       <span className="zuzu-counter">
        <CountUp end={50} duration={5} />
       </span>
       <strong>K</strong>
      </h2>
      <p>Monthly Users</p>
     </div>
     <div className="zuzu-counter-data" data-aos="fade-up" data-aos-delay="200">
      <h2>
       <span className="zuzu-counter">
        <CountUp end={400} duration={5} />
       </span>
       <strong>M+</strong>
      </h2>
      <p>Payments are processed annually</p>
     </div>
     <div className="zuzu-counter-data" data-aos="fade-up" data-aos-delay="300">
      <h2>
       <span className="zuzu-counter">
        <CountUp end={100} duration={5} />
       </span>
       <strong>+</strong>
      </h2>
      <p>Supported countries</p>
     </div>
     <div className="zuzu-counter-data" data-aos="fade-up" data-aos-delay="400">
      <h2>
       <span className="zuzu-counter">
        <CountUp end={20} duration={5} />
       </span>
       <strong>K+</strong>
      </h2>
      <p>Monthly transactions</p>
     </div>
    </div>
   </div>
  </div>
 );
};

export default CounterSection;
