import React from "react";
import { Link } from "../Link";

const TeamHeader = () => {
 return (
  <div className="zuzu-breadcrumb-section bg-gray-800 dark">
   <div className="container">
    <div
     className="breadcrumbs zuzu-inner-padding contact-hd-bg"
     style={{
      backgroundImage: `url('/shape/dots.png')`,
     }}
    >
     <h1
      className="wow fadeInUpX"
      data-wow-delay="0s"
      data-aos="fade-up"
      data-aos-delay="100"
     >
      Our Team
     </h1>
     <nav
      aria-label="breadcrumb"
      className="wow fadeInUpX"
      data-wow-delay="0.15s"
     >
      <ol className="breadcrumb" data-aos="fade-up" data-aos-delay="200">
       <li className="breadcrumb-item">
        <Link to="/">Home</Link>
       </li>
       <li className="breadcrumb-item active" aria-current="page">
        {" "}
        Our Team
       </li>
      </ol>
     </nav>
    </div>
   </div>
  </div>
 );
};

export default TeamHeader;
