import { myToast } from "fuhi-web3ts";
import React, { useEffect, useState } from "react";
import { ENV } from "../../environment";
import Loading from "../Common/loading";
import ContactHeader from "./ContactComon/ContactHeader";
import "./ContactDarkMap.css";

const ContactDarkMap = () => {
 const [isLoading, setLoading] = useState(true);
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [message, setMessage] = useState("");
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 const submit = () => {
  if (!name) {
   myToast.toast.error("Name is required");
   return;
  }
  if (!email) {
   myToast.toast.error("Email is required");
   return;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
   myToast.toast.error("Invalid email address");
   return;
  }
  myToast.toast.success("We will respond as soon as possible");
  setName("");
  setEmail("");
  setMessage("");
 };
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <ContactHeader />
   <div className="section zuzu-section-padding dark-version bg-gray-500 contact-page">
    <div className="container">
     <div className="row">
      <div className="col-lg-6">
       <div className="zuzu-contact-info-column">
        <div className="zuzu-default-content sm dark">
         <h2>Get In Touch</h2>
         <p>
          Please contact us if you have any questions, contact our team and we
          will respond as soon as possible.
         </p>
        </div>
        <div className="zuzu-contact-info-wrap">
         <a href="javascript:void(0)">
          <div className="zuzu-contact-info">
           <div className="zuzu-contact-info-icon">
            <img src={"/images/call.svg"} alt="" />
           </div>
           <div className="zuzu-contact-info-data">
            <h5>Our Social Media:</h5>
            <p>
             X: @NFTG_nft <br />
             Telegram: NFTG_nft
            </p>
           </div>
          </div>
         </a>
         <a href={"mailto:" + ENV.socials.email}>
          <div className="zuzu-contact-info">
           <div className="zuzu-contact-info-icon">
            <img src={"/images/message.svg"} alt="" />
           </div>
           <div className="zuzu-contact-info-data">
            <h5>Email us</h5>
            <p>{ENV.socials.email}</p>
           </div>
          </div>
         </a>
         <a href="javascript:void(0)">
          <div className="zuzu-contact-info m-0">
           <div className="zuzu-contact-info-icon">
            <img src={"/images/svg/map.svg"} alt="" />
           </div>
           <div className="zuzu-contact-info-data">
            <h5>Location</h5>
            <p>Greensburg, Pennsylvania </p>
           </div>
          </div>
         </a>
        </div>
       </div>
       <div className="zuzu-map">
        <div id="map">
         <iframe
          src="https://www.google.com/maps/embed/v1/search?q=Greensburg,+Pennsylvania&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          style={{
           border: 0,
           height: "100%",
           width: "100%",
           borderRadius: "15px",
          }}
          allowFullScreen=""
          loading="lazy"
          title="iframe"
          referrerPolicy="no-referrer-when-downgrade"
         ></iframe>
        </div>
       </div>
      </div>
      <div className="col-lg-6">
       <div className="zuzu-default-content dark">
        <h5>
         {" "}
         <strong>Contact Us</strong>{" "}
        </h5>
        <h2 className="font-black">Send us a message</h2>
        <p>
         If you have any questions about membership or would like to become a
         contributor, please fill out the form and our team will get back to
         you.
        </p>
       </div>
       <div className="zuzu-main-filed-wrap">
        <div className="zuzu-main-field">
         <label>Your name</label>
         <input
          type="text"
          placeholder="Name*"
          value={name}
          onChange={(e) => setName(e.target.value)}
         />
        </div>
        <div className="zuzu-main-field">
         <label>Email address</label>
         <input
          type="email"
          placeholder="Email Address*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
         />
        </div>
        <div className="zuzu-main-field">
         <label>Write your message</label>
         <textarea
          name="textarea"
          placeholder="Write your comment"
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
         ></textarea>
        </div>
        <button class="zuzu-btn" onClick={submit}>
         Send
        </button>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default ContactDarkMap;
