import React from "react";

const CoreValueItem = (props) => {
 return (
  <div
   className="col-lg-4 col-md-6"
   data-aos="fade-up"
   data-aos-delay={100 * props.index}
  >
   <div className="zuzu-iconbox-wrap7 dark wow fadeInUpX">
    <div className="zuzu-iconbox-icon7">
     <img src={props.data.img} alt="" />
    </div>
    <div className="zuzu-iconbox-data7">
     <h4>{props.data.title}</h4>
     <p>{props.data.desc}</p>
    </div>
   </div>
  </div>
 );
};

export default CoreValueItem;
