import React from "react";

import { myToast } from "fuhi-web3ts";
import { ENV } from "../../../environment";

const InstallSection = () => {
 const download = (type) => {
  if (ENV.app[type]) {
   const a = document.createElement("a");
   a.href = ENV.app[type];
   a.target = "_blank";
   a.click();
  } else {
   myToast.toast("Coming soon!", { type: "info" });
  }
 };
 return (
  <div className="zuzu-cta-section bg-gray-500">
   <div className="container">
    <div
     className="zuzu-cta-wrap add-bg"
     style={{
      backgroundImage: `url('/images/cta-bg.png')`,
     }}
    >
     <div className="row">
      <div className="col-xl-7 order-xl-2">
       <div className="zuzu-default-content dark large font-cabin">
        <h2>Download and enjoy the free NFT experience</h2>
        <p>
         Download and install the NFTG platform now to learn the safest,
         easiest, and smartest way to buy, store, exchange, and spend crypto and
         NFTs.
        </p>
        <div className="zuzu-cta-btn-wrap">
         <a
          className="zuzu-app-btn"
          href="javascript:void(0)"
          onClick={() => download("ios")}
         >
          <img src={"/images/app-store.svg"} alt="" />
         </a>
         <a
          className="zuzu-app-btn"
          href="javascript:void(0)"
          onClick={() => download("android")}
         >
          <img src={"/images/play-store.svg"} alt="" />
         </a>
        </div>
       </div>
      </div>
      <div className="col-xl-5">
       <div className="zuzu-cta-thumb wow fadeInUpX" data-wow-delay="0.1s">
        <img src={"/images/iPhone.png"} alt="" data-aos="fade-up" />
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default InstallSection;
