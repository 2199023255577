import React, { useMemo } from "react";

import { Accordion } from "react-bootstrap";
import { useNFT } from "../../context/NFTContext";
import { Link } from "../Link";
const DiscoverySection = () => {
 const { data: fullData } = useNFT();
 const nft1 = useMemo(() => {
  return fullData.length > 0
   ? fullData[Math.floor(Math.random() * fullData.length)]
   : undefined;
 }, [fullData]);
 const nft2 = useMemo(() => {
  return fullData.length > 0
   ? fullData[Math.floor(Math.random() * fullData.length)]
   : undefined;
 }, [fullData]);
 return (
  <>
   <div
    className="section zuzu-section-padding"
    style={{ backgroundColor: "#000" }}
   >
    <div className="container">
     <div className="row">
      <div className="col-lg-6">
       <div className="zuzu-cartoon-thumb">
        <img
         className="wow fadeInUpX"
         data-wow-delay=".10s"
         src={"/images/thumb1.png"}
         alt=""
         style={{ borderRadius: "15px" }}
        />
       </div>
      </div>
      <div className="col-lg-6 col-md-10 d-flex align-items-center">
       <div className="zuzu-default-content large" style={{ color: "#fff" }}>
        <h2 style={{ color: "#fff" }}>Know what NFTs are before investing</h2>
        <p>
         NFTs are digital files. They can be jpegs of a work of art, real
         estate, or video. Turning files into NFTs secures them through
         blockchain to make buying, selling, and trading efficient.
        </p>
        <p>
         NFTs are unique digital identifiers that cannot be copied, replaced or
         divided, recorded in the blockchain, and used to certify authenticity
         and ownership.
        </p>
        <div className="zuzu-btn-wrap">
         <Link className="zuzu-btn btn-pink rounded-pill" to="/collections">
          Discover More
         </Link>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div
    className="section zuzu-section-padding-bottom bg-cover bg-center bg-no-repeat bg-fixed"
    style={{ backgroundImage: "url('/images/bg-NFTG.png')" }}
   >
    <div className="container">
     <div className="row">
      <div className="col-lg-6 order-lg-2">
       <div className="zuzu-card-thumb-wrap">
        {nft1 && (
         <div
          className="zuzu-card-wrap zuzu-card5"
          style={{
           backgroundColor: "rgba(46, 46, 46, 0.733)",
           border: "1px solid gray",
          }}
         >
          <div className="zuzu-card-thumb">
           <img src={nft1.image} alt="" />
          </div>
          <div className="zuzu-card-data">
           <h3 style={{ color: "#fff" }}>{nft1.name}</h3>
           <div className="zuzu-card-footer-data">
            <h4 style={{ color: "#fff" }}>{nft1.id}</h4>
           </div>
          </div>
         </div>
        )}
        {nft2 && (
         <div
          className="zuzu-card-wrap zuzu-card4"
          style={{
           backgroundColor: "rgb(78 78 78 / 92%)",
           border: "1px solid gray",
          }}
         >
          <div className="zuzu-card-thumb">
           <img src={nft2.image} alt="" />
          </div>
          <div className="zuzu-card-data">
           <h3 style={{ color: "#fff" }}>{nft2.name}</h3>
           <div className="zuzu-card-footer-data">
            <h4 style={{ color: "#fff" }}>{nft2.id}</h4>
           </div>
          </div>
         </div>
        )}
       </div>
      </div>
      <div className="col-lg-6 col-md-10 d-flex align-items-center pt-10 ">
       <div className="zuzu-default-content large" style={{ color: "#eee" }}>
        <h2 style={{ color: "#fff" }}>How NFTG works for NFT collectibles</h2>
        <p>
         We are the next generation NFT marketplace built for the growing wave
         of creators, innovators and forward-thinking traders.
        </p>
        <div
         className="zuzu--accordion-one accordion-two accordion-six"
         id="accordionExample2"
        >
         <Accordion defaultActiveKey="0" flush style={{ color: "#fff" }}>
          <Accordion.Item eventKey="0">
           <Accordion.Header>Connect Wallet</Accordion.Header>
           <Accordion.Body>
            The safest way to secure your cryptocurrency is to use the best
            hardware wallets. If you can buy/sell/create NFTs.
           </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
           <Accordion.Header>Buy Your NFTs</Accordion.Header>
           <Accordion.Body>
            Buy your desired NFT for a new experience and enter the digital
            world with valuable assets.
           </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
           <Accordion.Header>NFT exchange</Accordion.Header>
           <Accordion.Body>
            Participate in buying, selling, exchanging, gifting NFTs to your
            friends and create a dynamic and completely natural marketplace.
           </Accordion.Body>
          </Accordion.Item>
         </Accordion>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default DiscoverySection;
