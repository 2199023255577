import { useEffect, useState } from "react";
import { ENV } from "../../environment";
export const Clock = () => {
 const [value, setValue] = useState({});

 useEffect(() => {
  let interval;
  const date = new Date(
   ENV.countdown.year,
   ENV.countdown.month - 1,
   ENV.countdown.day,
   ENV.countdown.hour,
   ENV.countdown.minute
  );
  if (date.getTime() > Date.now()) {
   interval = setInterval(() => {
    const distance = date.getTime() - Date.now(),
     day = Math.floor(distance / (1000 * 60 * 60 * 24)),
     hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
     minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
     second = Math.floor((distance % (1000 * 60)) / 1000);
    setValue({
     day,
     hour,
     minute,
     second,
    });
   }, 1000);
  }
  return () => {
   if (interval) clearInterval(interval);
  };
 }, []);
 return (
  <div className="md:basis-[64%] xl:basis-[62%]">
   <div className="mb-[12px] text-xs leading-[12px] font-medium text-primary-80 md:text-sm md:leading-[14px] lg:font-base lg:leading-[16px]">
    Presale Ends in
   </div>
   <div className="flex justify-center items-center bg-[rgba(255,255,255,0.19)] rounded-[12px] h-[48px] md:h-[80px] lg:h-[86px] xl:h-[112px] lg:mt-[20px] xl:mt-0">
    <ClockItem value={value.day} text="D" />
    <ClockItem value={value.hour} text="H" />
    <ClockItem value={value.minute} text="M" />
    <ClockItem value={value.second} text="S" />
   </div>
  </div>
 );
};

const ClockItem = ({ text, value }) => {
 return (
  <div className="flex">
   <div className="tracking-widest text-primary-100 tabular-nums text-lg leading-[18px] md:text-2xl md:leading-[24px] lg:text-[32px] lg:leading-[32px]">
    {value < 10 ? "0" + value : value}
    <span className="text-xs leading-[12px] text-primary-54 ml-[4px] md:text-base md:leading-[16px] lg:text-lg lg:leading-[18px]">
     {text}
    </span>
   </div>
   <div className="w-[1px] bg-white mx-[10px] h-[12px] md:h-[24px] my-auto" />
  </div>
 );
};
