import React from "react";
import { Link } from "../Link";
const NavItems = (props) => {
 const print = () => {
  props.onClick();
 };

 const handleItem = (item, index) => {
  if (item.items.length > 0) {
   return (
    <li
     className="nav-item nav-item-has-children"
     key={index}
     onClick={() => print()}
    >
     <Link to="/" className="nav-link-item drop-trigger">
      {item.name} <i className="fas fa-angle-down"></i>
     </Link>
     <ul
      className={props.isSubActive ? "sub-menu active" : "sub-menu"}
      id="submenu-1"
     >
      {item.items.map((x, i) => handleSubItem(x, i))}
     </ul>
    </li>
   );
  }
  return (
   <li className="nav-item" key={index}>
    <Link to={item.url} className="nav-link-item">
     {item.name}
    </Link>
   </li>
  );
 };

 const handleSubItem = (sub_item, index) => {
  if (sub_item.sub_items.length > 0) {
   return (
    <li className="sub-menu--item nav-item-has-children" key={index + "sub"}>
     <Link to={sub_item.url}>
      {sub_item.sub_name} <i className="fas fa-angle-down"></i>
     </Link>
     <ul className="sub-menu shape-none" id="submenu-10">
      {sub_item.sub_items.map((x, i) => (
       <li className="sub-menu--item" key={x + i}>
        <Link to={x.url}>{x.sub_item_name}</Link>
       </li>
      ))}
     </ul>
    </li>
   );
  }
  return (
   <li className="sub-menu--item nav-item-has-children" key={index + "sub"}>
    <Link to={sub_item.url}>{sub_item.sub_name}</Link>
   </li>
  );
 };
 return (
  <ul className="site-menu-main">
   {props.data.map((item, index) => handleItem(item, index))}
  </ul>
 );
};

export default NavItems;
