import React, { useMemo, useState } from "react";

import Pagination from "react-bootstrap/Pagination";
import { useNFT } from "../../context/NFTContext";
import { NFTItem } from "../NFTItem/NFTItem";
const PAGE_SIZE = 8;
const CollectionCards = () => {
 const { data: fullData } = useNFT();
 const [currentPage, setCurrentPage] = useState(0);

 const data = useMemo(() => {
  const start = PAGE_SIZE * currentPage,
   end = start + PAGE_SIZE;
  return fullData.slice(start, end);
 }, [fullData, currentPage]);

 const pageCount = useMemo(
  () => Math.ceil(fullData.length / PAGE_SIZE),
  [fullData]
 );
 const handlePageChange = (newPage) => {
  setCurrentPage(newPage);
 };

 return (
  <div className="coll-card-container   ">
   <div className="container">
    <div className="row">
     {data.map((item) => (
      <NFTItem value={item} key={item.id} />
     ))}
    </div>
    <Pagination size="sm" className="mt-3">
     <Pagination.Prev
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 0}
     />
     {Array.from({ length: pageCount }, (_, index) => index).map((page) => (
      <Pagination.Item
       key={page}
       active={currentPage === page}
       onClick={() => handlePageChange(page)}
      >
       {page + 1}
      </Pagination.Item>
     ))}
     <Pagination.Next
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === pageCount - 1}
     />
    </Pagination>
   </div>
  </div>
 );
};

export default CollectionCards;
