import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccount, useRefAddress } from "fuhi-web3ts";

export const Referral = () => {
 const { createInvite } = useRefAddress();
 const { account } = useAccount();
 return (
  <div
   className="flex flex-col flex-1 items-center overflow-hidden w-fit cursor-pointer hover:brightness-125"
   onClick={createInvite}
  >
   <div className="flex items-center text-primary-100 text-base leading-[16px] md:text-xl md:leading-[20px] lg:text-2xl lg:leading-[24px]">
    <div className="truncate">
     {account.slice(0, 6) + "..." + account.slice(-6)}
    </div>
    <div className="inline-block md:hidden">
     <div
      type="button"
      aria-haspopup="dialog"
      aria-expanded="false"
      aria-controls="radix-:r16:"
      data-state="closed"
      className="cursor-pointer"
     >
      <svg
       width="undefinedpx"
       height="undefinedpx"
       viewBox="0 0 20 20"
       fill="currentColor"
       xmlns="http://www.w3.org/2000/svg"
       className="ml-[2px] w-[20px] h-[20px] text-primary-36"
      >
       <mask
        id="mask0_826_134692"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
        style={{ maskType: "alpha" }}
       >
        <rect width={20} height={20} fill="#D9D9D9" />
       </mask>
       <g mask="url(#mask0_826_134692)">
        <path d="M9.25 14H10.75V9H9.25V14ZM9.9956 7.5C10.2069 7.5 10.3854 7.42855 10.5312 7.28565C10.6771 7.14273 10.75 6.96565 10.75 6.7544C10.75 6.54313 10.6785 6.36458 10.5356 6.21875C10.3927 6.07292 10.2156 6 10.0044 6C9.79313 6 9.61458 6.07145 9.46875 6.21435C9.32292 6.35727 9.25 6.53435 9.25 6.7456C9.25 6.95687 9.32145 7.13542 9.46435 7.28125C9.60727 7.42708 9.78435 7.5 9.9956 7.5ZM10.0058 18C8.9047 18 7.86806 17.7917 6.89583 17.375C5.92361 16.9583 5.07292 16.3854 4.34375 15.6562C3.61458 14.9271 3.04167 14.0767 2.625 13.105C2.20833 12.1334 2 11.0952 2 9.99046C2 8.88571 2.20833 7.85069 2.625 6.88542C3.04167 5.92014 3.61458 5.07292 4.34375 4.34375C5.07292 3.61458 5.92332 3.04167 6.89496 2.625C7.86661 2.20833 8.90481 2 10.0095 2C11.1143 2 12.1493 2.20833 13.1146 2.625C14.0799 3.04167 14.9271 3.61458 15.6562 4.34375C16.3854 5.07292 16.9583 5.92169 17.375 6.89008C17.7917 7.85849 18 8.89321 18 9.99425C18 11.0953 17.7917 12.1319 17.375 13.1042C16.9583 14.0764 16.3854 14.9271 15.6562 15.6562C14.9271 16.3854 14.0783 16.9583 13.1099 17.375C12.1415 17.7917 11.1068 18 10.0058 18ZM10 16.5C11.8056 16.5 13.3403 15.8681 14.6042 14.6042C15.8681 13.3403 16.5 11.8056 16.5 10C16.5 8.19444 15.8681 6.65972 14.6042 5.39583C13.3403 4.13194 11.8056 3.5 10 3.5C8.19444 3.5 6.65972 4.13194 5.39583 5.39583C4.13194 6.65972 3.5 8.19444 3.5 10C3.5 11.8056 4.13194 13.3403 5.39583 14.6042C6.65972 15.8681 8.19444 16.5 10 16.5Z" />
       </g>
      </svg>
     </div>
    </div>
    <div className="hidden md:inline-block">
     <div data-state="closed" className="cursor-pointer">
      <svg
       width="undefinedpx"
       height="undefinedpx"
       viewBox="0 0 20 20"
       fill="currentColor"
       xmlns="http://www.w3.org/2000/svg"
       className="ml-[2px] w-[20px] h-[20px] text-primary-36"
      >
       <mask
        id="mask0_826_134692"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
        style={{ maskType: "alpha" }}
       >
        <rect width={20} height={20} fill="#D9D9D9" />
       </mask>
       <g mask="url(#mask0_826_134692)">
        <path d="M9.25 14H10.75V9H9.25V14ZM9.9956 7.5C10.2069 7.5 10.3854 7.42855 10.5312 7.28565C10.6771 7.14273 10.75 6.96565 10.75 6.7544C10.75 6.54313 10.6785 6.36458 10.5356 6.21875C10.3927 6.07292 10.2156 6 10.0044 6C9.79313 6 9.61458 6.07145 9.46875 6.21435C9.32292 6.35727 9.25 6.53435 9.25 6.7456C9.25 6.95687 9.32145 7.13542 9.46435 7.28125C9.60727 7.42708 9.78435 7.5 9.9956 7.5ZM10.0058 18C8.9047 18 7.86806 17.7917 6.89583 17.375C5.92361 16.9583 5.07292 16.3854 4.34375 15.6562C3.61458 14.9271 3.04167 14.0767 2.625 13.105C2.20833 12.1334 2 11.0952 2 9.99046C2 8.88571 2.20833 7.85069 2.625 6.88542C3.04167 5.92014 3.61458 5.07292 4.34375 4.34375C5.07292 3.61458 5.92332 3.04167 6.89496 2.625C7.86661 2.20833 8.90481 2 10.0095 2C11.1143 2 12.1493 2.20833 13.1146 2.625C14.0799 3.04167 14.9271 3.61458 15.6562 4.34375C16.3854 5.07292 16.9583 5.92169 17.375 6.89008C17.7917 7.85849 18 8.89321 18 9.99425C18 11.0953 17.7917 12.1319 17.375 13.1042C16.9583 14.0764 16.3854 14.9271 15.6562 15.6562C14.9271 16.3854 14.0783 16.9583 13.1099 17.375C12.1415 17.7917 11.1068 18 10.0058 18ZM10 16.5C11.8056 16.5 13.3403 15.8681 14.6042 14.6042C15.8681 13.3403 16.5 11.8056 16.5 10C16.5 8.19444 15.8681 6.65972 14.6042 5.39583C13.3403 4.13194 11.8056 3.5 10 3.5C8.19444 3.5 6.65972 4.13194 5.39583 5.39583C4.13194 6.65972 3.5 8.19444 3.5 10C3.5 11.8056 4.13194 13.3403 5.39583 14.6042C6.65972 15.8681 8.19444 16.5 10 16.5Z" />
       </g>
      </svg>
     </div>
    </div>
   </div>
   <div className="text-primary-54 mt-[8px] text-xs leading-[12px] md:text-sm md:leading-[14px] flex space-x-2">
    <span>My referral</span>
    <FontAwesomeIcon
     icon={faInfoCircle}
     className="text-white"
     title="Refer 100 friends to participate and receive a reward of 5000 USDT and NFT of Ronaldo player worth 3000 USDT"
    />
   </div>
  </div>
 );
};
