import React, { useEffect, useState } from "react";
import Loading from "../Common/loading";
import BannerSection from "../CryptoCard/SlideSection/SlideSection";
import CardSection from "./CardSection";
import CollectionSection from "./CollectionSection";
import CreativeSection from "./CreativeSection";
import DiscoverySection from "./DiscoverySection";
import HistorySection from "./HistorySection";
import QuestionSection from "./QuestionSection";
import SubcribeSection from "./SubcribeSection";

const NftPlatform = () => {
 const [isLoading, setLoading] = useState(true);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <CollectionSection />
   <DiscoverySection />
   <HistorySection />
   <CardSection />
   <BannerSection />
   <CreativeSection />
   <QuestionSection title={"Frequently asked questions about NFTs"} />
   <SubcribeSection />
  </>
 );
};

export default NftPlatform;
