import { myToast } from "fuhi-web3ts";
import React, { useEffect, useState } from "react";
import { ENV } from "../../environment";
import { Link } from "../Link";
const Footer = () => {
 const [socials, setSocials] = useState([]);
 const [email, setEmail] = useState("");
 useEffect(() => {
  const socials = [
   {
    logo: "/images/social/twitter.svg",
    url: "",
    name: "x",
   },
   {
    logo: "/images/social/telegram.svg",
    url: "",
    name: "telegram",
   },
   {
    logo: "/images/social/email.svg",
    url: "",
    name: "email",
   },
   {
    logo: "/images/social/github.svg",
    url: "",
    name: "github",
   },
   {
    logo: "/images/social/youtube.svg",
    url: "",
    name: "youtube",
   },
  ];

  socials.forEach((social) => {
   social.url = ENV.socials[social.name];
   if (social.name === "email") {
    social.url = "mailto:" + social.url;
   }
  });
  setSocials(socials);
 }, []);
 return (
  <footer className="zuzu-footer-section bg-gray-500">
   <div className="container">
    <div className="zuzu-footer-top">
     <div className="row">
      <div className="col-xl-3">
       <div className="zuzu-textarea">
        <div className="zuzu-footer-logo">
         <img src={"/logo.png"} alt="" className="max-w-80" />
        </div>
        <p>
         Explore NFTs by category, follow the latest installments, and follow
         your favorite collections to enjoy it!
        </p>
        <div className="zuzu-social-icon">
         <ul>
          {socials.map((social) => (
           <li key={social.name}>
            <a
             href={social.url ?? "#"}
             target="_blank"
             onClick={(e) => {
              if (!social.url) {
               myToast.toast.info("Our platform will be available soon");
               e.preventDefault();
              }
             }}
             rel="noreferrer"
            >
             <img src={social.logo} alt="" />
            </a>
           </li>
          ))}
         </ul>
        </div>
       </div>
      </div>
      <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-3">
       <div className="zuzu-footer-menu">
        <span>Community</span>
        <ul>
         <li>
          <Link to="/features">Features </Link>
         </li>
         <li>
          <Link to="/aboutus">About Us </Link>
         </li>
         <li>
          <Link to="/team">Team</Link>
         </li>
         <li>
          <Link to="/contact">Contact</Link>
         </li>
        </ul>
       </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-md-3">
       <div className="zuzu-footer-menu zuzu-footer-margin">
        <span>Marketplace</span>
        <ul>
         <li>
          <Link to="/nftplatform">NFT Platform </Link>
         </li>
         <li>
          <Link to="/collections">Explore</Link>
         </li>
         <li>
          <Link to="/nftplatform#faqs">FAQs</Link>
         </li>
         <li>
          <Link to={ENV.whitepaper}>Whitepaper</Link>
         </li>
        </ul>
       </div>
      </div>
      <div className="col-xl-3 col-lg-5 col-md-6">
       <div className="zuzu-footer-menu">
        <span>Subscribe to our newsletter</span>
        <p>
         Join our newsletter to stay up to date on NFTs and our latest artwork.
        </p>
        <div className="zuzu-footer-subscribe relative flex rounded-full bg-white">
         <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className=" w-full border-none bg-transparent flex-1 px-2"
          type="email"
          placeholder="Enter your email"
         />
         <button
          type="submit"
          className="zuzu-btn"
          onClick={() => {
           if (!email) {
            myToast.toast.error("Email is required");
            return;
           }
           const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
           if (!emailRegex.test(email)) {
            myToast.toast.error("Invalid email address");
            return;
           }
           myToast.toast.success("We will respond as soon as possible");
           setEmail("");
          }}
         >
          Subscribe
         </button>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div className="zuzu-footer-bottom">
     <div className="row">
      <div className="col-lg-6 col-md-8">
       <p>&copy; Copyright 2024, All Rights Reserved by {ENV.tokenSymbol}</p>
      </div>
     </div>
    </div>
   </div>
  </footer>
 );
};

export default Footer;
