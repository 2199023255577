import { NumberFormater, useBuyPresale } from "fuhi-web3ts";
import { useBuy } from "../BuyContext";

export const Balance = () => {
 const { balance } = useBuyPresale();
 const { symbol } = useBuy();
 return (
  <div>
   <div className="text-base leading-[16px] md:text-xl md:leading-[20px]">
    Your balance
   </div>
   <div className="flex items-center mt-[8px]">
    <div className="text-xs leading-[12px] text-primary-54 md:text-sm md:leading-[14px]">
     {NumberFormater.format(balance)} {symbol}
    </div>
   </div>
  </div>
 );
};
