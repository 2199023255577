import { myToast, useData } from "fuhi-web3ts";
import React, { useEffect, useState } from "react";
import Loading from "../Common/loading";
import TeamHeader from "./TeamHeader";
import { TeamItem } from "./TeamItem";

const TeamDark = () => {
 const { data } = useData({ path: "/data/teams.json", default: [] });
 const [isLoading, setLoading] = useState(true);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 return isLoading ? (
  <Loading />
 ) : (
  <>
   <TeamHeader />
   <div className="section zuzu-section-padding3 bg-gray-500">
    <div className="container">
     <div className="row">
      {data.map((item, idx) => (
       <TeamItem item={item} key={idx} />
      ))}
      <div className="col-xl-4 col-md-6 offset-xl-0 offset-lg-3 offset-md-3">
       <div
        className="zuzu-team-wrap dark wow fadeInUpX wrap2"
        data-wow-delay=".80s"
       >
        <h4>Are you interested in working with us?</h4>
        <a
         className="zuzu-btn pill btn-blue"
         href="javascript:void(0)"
         onClick={() =>
          myToast.toast.info("We will open recruitment mode in the future")
         }
        >
         See opening jobs
        </a>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default TeamDark;
