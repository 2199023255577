import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ENV } from "../../environment";
import { Link } from "../Link";
import NavItems from "./navitem";
const data = [
 {
  name: "Home",
  url: "/",
  items: [],
 },
 {
  name: "NFT Platform",
  url: "/nftplatform",
  items: [],
 },
 {
  name: "Features",
  url: "/features",
  items: [],
 },
 {
  name: "About Us",
  url: "/aboutus",
  items: [],
 },
 {
  name: "Team",
  url: "/team",
  items: [],
 },
 {
  name: "Staking",
  url: "/staking",
  items: [],
 },
 {
  name: "Contact Us",
  url: "/contact",
  items: [],
 },
];
const Navbar = (props) => {
 const [isFix, setFix] = useState("");
 const [isActive, setActive] = useState(false);
 const [isSubActive, setSubActive] = useState(false);
 const location = useLocation();
 useEffect(() => {
  if (props.fix) {
   setFix(
    "site-header zuzu-header-dark zuzu-header-section sticky-menu " +
     props.headerClassname ?? ""
   );
  } else {
   setFix(
    "site-header zuzu-header-dark zuzu-header-section " +
     props.headerClassname ?? ""
   );
  }
 }, [isFix, props]);

 const handleMenu = () => {
  setActive(!isActive);
  if (isActive === false) {
   setSubActive(false);
  }
 };

 const handleSubMenu = () => {
  setSubActive(!isSubActive);
 };

 return (
  <header className={isFix} id="sticky-menu">
   <div className="container-fluid">
    <nav className="navbar site-navbar">
     <div className="brand-logo zuzu-menu-left">
      <Link to="/">
       <img src={"/logo.png"} alt="" className="max-w-20" />
      </Link>
     </div>
     <div className="menu-block-wrapper">
      <div className="menu-overlay"></div>
      <nav
       className={isActive ? "menu-block active" : "menu-block"}
       id="append-menu-header"
      >
       <div
        className={isSubActive ? "mobile-menu-head active" : "mobile-menu-head"}
       >
        <div className="go-back" onClick={() => handleSubMenu()}>
         <i className="fa fa-angle-left"></i>
        </div>
        <div className="current-menu-title"></div>
        <div className="mobile-menu-close" onClick={() => handleMenu()}>
         &times;
        </div>
       </div>
       <NavItems
        data={data}
        onClick={() => handleSubMenu()}
        isSubActive={isSubActive}
       />
      </nav>
     </div>
     <div className="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
      {location.pathname !== "/presale" && (
       <Link
        className="zuzu-btn zuzu-header-btn rounded-pill black"
        to="/presale"
       >
        Buy {ENV.tokenSymbol}
       </Link>
      )}
     </div>
     <div className="mobile-menu-trigger" onClick={() => handleMenu()}>
      <span></span>
     </div>
    </nav>
   </div>
  </header>
 );
};

export default Navbar;
