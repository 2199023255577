import React from "react";

import { useData } from "fuhi-web3ts";
import CoreValueItem from "./CoreValueItem";

const CoreValueSection = () => {
 const { data } = useData({ path: "/data/corevaluedata.json", default: [] });
 return (
  <div className="section zuzu-section-padding3 bg-gray-800">
   <div className="container">
    <div className="zuzu-section-title">
     <div className="zuzu-default-content dark">
      <h5>
       {" "}
       <strong>Our core values</strong>{" "}
      </h5>
      <h2 className="font-black">
       Innovative features make us unique to everyone
      </h2>
     </div>
    </div>
    <div className="row">
     {data.map((item, index) => (
      <CoreValueItem data={item} key={index} index={index} />
     ))}
    </div>
   </div>
  </div>
 );
};

export default CoreValueSection;
