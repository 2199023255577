import React from 'react'

const Loading = () => {
  return (
    <div className="zuzu-preloader">
      <div className="preloader-square-swapping">
        <div className="cssload-square-part cssload-square-green"></div>
        <div className="cssload-square-part cssload-square-pink"></div>
        <div className="cssload-square-blend"></div>
      </div>
    </div>
  )
}

export default Loading