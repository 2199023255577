import React from "react";

import { useData } from "fuhi-web3ts";
import Slider from "react-slick";
import SlideItem from "./SlideItem";

const ExperienceSection = () => {
 const { data } = useData({ path: "/data/objective_team.json", default: [] });
 var settings = {
  dots: true,
  draggable: true,
  autoplaySpeed: 2000,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 5,
     slidesToScroll: 1,
    },
   },
   {
    breakpoint: 1000,
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };
 return (
  <div className="section bg-gray-500 zuzu-section-padding2">
   <div className="container">
    <div className="zuzu-section-title">
     <div className="zuzu-default-content dark large font-cabin">
      <h2 className="letter-sp-s2">Objective comments come from our team</h2>
      <p>
       We are formed by outstanding individuals with experience with the Web3
       platform. The knowledge and experience of these individuals cannot be
       ignored.
      </p>
     </div>
    </div>
   </div>
   <Slider {...settings} className="zuzu-testimonial-slider">
    {data.map((item, index) => (
     <SlideItem
      key={index}
      title={item.title}
      des={item.description}
      image={item.avt}
      name={item.name}
      email={item.email}
     />
    ))}
   </Slider>
  </div>
 );
};

export default ExperienceSection;
