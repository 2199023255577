/* eslint-disable react/prop-types */
import { useLocation, useNavigate } from "react-router-dom";

export function Link(props) {
 const { to } = props;
 const { search } = useLocation();
 const goTo = (id) => {
  const el = document.getElementById(id);
  if (el) {
   window.scrollTo({
    top: el.offsetTop - 100,
    behavior: "smooth",
   });
  }
 };
 const navigate = useNavigate();
 const handleClick = (e) => {
  e.preventDefault();
  if (to) {
   if (to.includes("#")) {
    if (to.indexOf("#") > 2) {
     const href = to.substring(0, to.indexOf("#")) + `${search}`;
     navigate(href);
     setTimeout(() => {
      goTo(to.substring(to.indexOf("#") + 1));
     }, 2000);
    } else {
     goTo(to.substring(to.indexOf("#") + 1));
    }
   } else {
    if (props.to.includes("http")) {
     const a = document.createElement("a");
     a.href = to;
     a.target = "_blank";
     a.click();
    } else {
     navigate(props.to + `${search}`);
    }
   }
  }
 };
 return (
  <a
   href={to}
   className={`cursor-pointer ${!props.noUnderline ? "hover:underline" : ""} ${
    props.className || ""
   }`}
   onClick={handleClick}
  >
   {props.children}
  </a>
 );
}
