import { BuyPresaleProvider, Web3TsProvider, bsc } from "fuhi-web3ts";
import { ENV } from "../../environment";
import { BuyBanner } from "./Banner";
import { BuyProvider } from "./BuyContext";
import { BuyLeftPanel } from "./Left";
import { SwapContainer } from "./Swap";
import { BuyTop } from "./Top";

export const BuyPage = () => {
 return (
  <BuyPresaleProvider>
   <BuyProvider>
    <div
     className="bg-[#000000] text-white min-h-screen bg-fixed bg-cover bg-no-repeat pb-40"
     style={{
      backgroundImage: "url('/images/buy-bg.png')",
     }}
    >
     <BuyTop />
     <div className="max-w-[1440px] mx-auto px-[20px] md:px-[40px] lg:px-[60px] xl:px-[80px] relative z-20">
      <BuyBanner />
      <div className="pt-20 grid grid-cols-1 xl:grid-cols-2 w-full font-medium gap-y-[18px] md:gap-y-[40px] lg:gap-x-[20px]">
       <BuyLeftPanel />
       <SwapContainer />
      </div>
     </div>
    </div>
   </BuyProvider>
  </BuyPresaleProvider>
 );
};
