import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { cmccService } from "../../../services/CMCC.js";

const SlideSection = () => {
 const [data, setData] = useState([]);
 useEffect(() => {
  cmccService
   .getMany({
    limit: 20,
    page: 0,
   })
   .then(setData);
 }, []);
 return (
  <div className="zuzu-text-slider-section bg-[#4961e0]">
   <div className="slide-custom-contain">
    {data.map((item) => (
     <Item key={item.id} item={item} />
    ))}
   </div>
  </div>
 );
};
const Item = ({ item }) => {
 const percent = Math.round(item.quote.USD.percent_change_24h * 100) / 100;

 return (
  <div className="slide-item" key={item.id}>
   <div className="pl-6 flex gap-x-2 items-center font-bold">
    <img
     className="h-6"
     src={
      "https://s2.coinmarketcap.com/static/img/coins/64x64/" + item.id + ".png"
     }
     alt=""
    />
    <span className="text-white">{item.symbol}</span>
    <span className="text-slate-200 text-xs whitespace-nowrap">
     ({item.name})
    </span>

    <div
     className={
      (percent >= 0 ? "text-green-400" : "text-red-400") +
      " text-center flex items-center gap-x-2 justify-end"
     }
    >
     <FontAwesomeIcon icon={percent >= 0 ? faArrowUp : faArrowDown} />
     {Math.abs(percent)}%
    </div>
   </div>
  </div>
 );
};
export default SlideSection;
