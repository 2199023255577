import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConnect } from "fuhi-web3ts";
import { Link } from "../Link";

export const NFTItem = ({ value, theme }) => {
 const { isConnected, connect } = useConnect();
 return (
  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
   <div className="zuzu-card-wrap rt-mb-24 wow fadeInUpX" data-wow-delay="0s">
    <div className="zuzu-card-thumb">
     <img src={value.image} alt="" />
    </div>
    <div className="zuzu-card-data">
     <div className="flex space-x-2 items-center justify-between">
      <h3 className="line-clamp-1">{value.name}</h3>
      <span className="text-gray-900 text-xs">{value.id}</span>
     </div>
     <div className="zuzu-card-footer-data">
      <h4>
       {isConnected ? (
        (value.price ?? "--") + " BNB"
       ) : (
        <button
         className="text-xs hover:underline flex space-x-1 items-center"
         onClick={connect}
        >
         <span>Connect wallet</span>
         <FontAwesomeIcon
          icon={faInfoCircle}
          title="Connect wallet to see current price of this NFT"
         />
        </button>
       )}
      </h4>
      <Link
       className="zuzu-card-btn bg-success-300"
       to={`/nft/${value.marketItemId}`}
      >
       Buy
      </Link>
     </div>
    </div>
   </div>
  </div>
 );
};
