import React from "react";

import Accordion from "react-bootstrap/Accordion";
import { ENV } from "../../environment";
import { Link } from "../Link";

const MidSection = () => {
 return (
  <>
   <div className="section bg-gray-500">
    <div className="container">
     <div className="row">
      <div className="col-lg-6">
       <div
        className="zuzu-earth-thumb wow fadeInUpX zuzu-tilt"
        id="zuzu-tilt"
        data-wow-delay=".10s"
       >
        <img src={"/images/earth2.png"} alt="" />
       </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center">
       <div className="zuzu-default-content sm dark font-syne">
        <h2>Especially 5G technology is promising and revolutionary</h2>
        <p>
         Blockchain is a particularly promising and revolutionary technology
         because it helps reduce security risks, prevent fraud, and provide
         transparency in a scalable way.
        </p>
        <p>
         By combining with 5G technology, we bring a new experience with
         strongly enhanced features.
        </p>
        <div className="zuzu-btn-wrap">
         <Link
          className="zuzu-btn active gradient text-white"
          to={ENV.whitepaper}
         >
          View more
         </Link>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div className="section bg-gray-500 zuzu-section-padding2">
    <div className="container">
     <div className="row">
      <div className="col-lg-6 order-lg-2 d-lg-flex justify-content-lg-end">
       <div className="zuzu-earth-thumb wow fadeInUpX" data-wow-delay=".10s">
        <img src={"/images/earth.png"} alt="" />
       </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center">
       <div className="zuzu-default-content sm dark font-syne">
        <h2>
         Blockchain has important implications in the modern business world
        </h2>
        <p>
         20% of the global population owns cryptocurrency. 34% of Americans have
         invested in cryptocurrency. Using blockchain, financial institutions
         can save up to $12 billion per year.
        </p>
        <div
         className="zuzu--accordion-one accordion-two accordion-six"
         id="accordionExample"
        >
         <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
           <Accordion.Header>Building trust</Accordion.Header>
           <Accordion.Body>
            Bitcoin and cryptocurrencies in general are iconic examples of how
            blockchain builds trust between entities and customers.
           </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
           <Accordion.Header>Reducing costs</Accordion.Header>
           <Accordion.Body>
            Bitcoin and cryptocurrencies in general are iconic examples of how
            blockchain builds trust between entities and customers.
           </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
           <Accordion.Header>Bringing innovation</Accordion.Header>
           <Accordion.Body>
            Bitcoin and cryptocurrencies in general are iconic examples of how
            blockchain builds trust between entities and customers.
           </Accordion.Body>
          </Accordion.Item>
         </Accordion>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default MidSection;
