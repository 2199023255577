import { myToast, useData } from "fuhi-web3ts";
import { useEffect, useState } from "react";
import Loading from "../../components/Common/loading";
import { StakingJob } from "./StakingJob";

export const StakingPage = () => {
 const { data: CONFIG } = useData({
  default: { packages: [] },
  path: "/data/staking.json",
 });
 const [isLoading, setLoading] = useState(true);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 500);
 });
 if (isLoading) return <Loading />;
 return (
  <main className="flex-1 max-w-[100vw] min-h-dvh text-white">
   <div className="pt-[76px] sm:pt-[128px] lg:pt-[160px] bg-no-repeat bg-center">
    <div className="px-5 sm:px-12 lg:px-[72px] w-[100vw] max-w-[1424px] mx-auto">
     <div className="flex-col flex justify-center flex-wrap items-center">
      <div className="font-mona font-extrabold text-size-28 sm:text-8xl">
       NFTG Staking
      </div>
      <div className="mt-2 flex items-center text-center w-full sm:w-auto flex-col sm:flex-row text-lg">
       <span>
        Participate in governance of the NFTG ecosystem and earn rewards.
       </span>
      </div>
      <div className="mt-8 sm:flex">
       <div className="flex flex-col items-center">
        <div className="text-lg"> Total NFTG Staked</div>
        <div className="flex items-center mt-2">
         <img
          alt=""
          loading="lazy"
          width={32}
          height={32}
          decoding="async"
          data-nimg={1}
          className="w-8 h-8 sm:w-10 sm:h-10"
          src="/logo4wallet.png"
          style={{ color: "transparent" }}
         />
         <div className="text-size-28 font-bold ml-4 ">--</div>
        </div>
       </div>
      </div>
     </div>
     <div>
      <div className="relative mt-[48px] sm:mt-[80px] lg:mt-[100px]">
       <div className="p-6 sm:p-9 h-auto sm:h-[216px]  rounded-2xl flex bg-background-darken1 flex-col justify-center items-center relative border border-white">
        <div className="text-info-lighten1">
         Your information will appear here
        </div>
        <button
         className="zuzu-btn mt-2"
         onClick={() => myToast.toast.info("Staking feature coming soon!")}
        >
         Login
        </button>
       </div>
      </div>
     </div>
     <div className="partner-info stake-part mb-[60px] sm:mb-[120px] mt-[60px] sm:mt-[80px] lg:mt-[100px]">
      <div className="font-mona font-extrabold text-4xl sm:text-5xl">Earn</div>
      <div className=" text-white/80 mt-2 mb-5 text-lg sm:mb-9 sm:text-base">
       NFTG stakers may be eligible to receive airdrops from partners in the
       NFTG ecosystem. Powered by NFTG Quest, NFTG Earn facilitates direct
       participation and smarter reward distribution.
      </div>
      {CONFIG.packages.map((value, idx) => (
       <StakingJob key={idx} value={value} />
      ))}
     </div>
    </div>
   </div>
  </main>
 );
};
